import React from "react";
import Filter from "./Filter";
function ProductContainer({ children, cats, filterCb, activeCatId, themeColor }) {
  return (
    <div className="container">
      <Filter cats={cats} filterCb={filterCb} activeCatId={activeCatId} themeColor={themeColor} />
      <div className="row featured__filter">{children}</div>
    </div>
  );
}

export default ProductContainer;
