import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import RentProdSection from "./renting/RentProdSection";
import EcomProdSection from "./e-commerce/EcomProdSection";
import { inject, observer } from "mobx-react";
import CurHolder from "./CurHolder";
import PackProdElements from "./PackProdElements";
import PackProdItem from "./PackProdItem";
import style from "../utils/style";
import PackInfoCard from "./pack/PackInfoCard";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
function ProductSection({ location, history, store }) {
  // reset scroll position when component is about to mount
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  //redirect if no location state found(user didn't get to product page clicking a product link )
  if (location.state === undefined) history.push("/renting");
  const locState = location.state === undefined ? {} : location.state;
  const {
    desc,
    name,
    price,
    img,
    type,
    curr,
    quantity,
    products,
    totalBefore,
    discount,
    packInfoArray,
    packId,
    cadance,
    tax, 
    images,
    productsPath
  } = locState;
  const lng = store.getLang;
  const userData = store.getUser;
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  //image Zoomer
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  // image changer
  const [imgechanger, setImageChanger] = useState(null); 

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setPosition({ x, y });
    setCursorPosition({ x: e.pageX - left, y: e.pageY - top });
  };

  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  return (
    <section className="product-details spad" style={{marginTop: '10%'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6"  style={{overflow: 'hidden'}}>
            <div className="product__details__pic">
              <div className="product__details__pic__item"
                    style={{
                      backgroundImage: `url(${imgechanger!== null ? imgechanger :img})`, 
                      
                    }}
                    onMouseEnter={() => setShowMagnifier(true)}
                    onMouseLeave={() => setShowMagnifier(false)}
                    onMouseMove={handleMouseMove}
              >
                {/* <img
                  className="product__details__pic__item--large"
                  src={imgechanger!== null ? imgechanger :img}
                  alt=""
                /> */}
                {showMagnifier && (
                  <div
                    style={{
                      position: "absolute",
                      left: `${cursorPosition.x - 100}px`,
                      top: `${cursorPosition.y - 100}px`,
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      className="magnifier-image"
                      style={{
                        backgroundImage: `url(${imgechanger!== null ? imgechanger : img})`,
                        backgroundPosition: `${position.x}% ${position.y}%`,
                      }}
                    />
                  </div>
                )}
              </div>
              {/* <div className="product__details__pic__slider owl-carousel">
                  <div style={{padding: "10px", backgroundColor: "#eee", borderRadius: "8px", width: "25%", height: "10%"}}>
                    <img
                      data-imgbigurl="img/product/details/product-details-2.jpg"
                      src={img}
                      alt=""
                      style={{width: '100%', height: '100%'}}
                    />
                  </div>
                  <div style={{padding: "10px", backgroundColor: "#eee", borderRadius: "8px", width: "25%", height: "10%"}}>
                    <img
                      data-imgbigurl="img/product/details/product-details-3.jpg"
                      src={img}
                      alt=""
                      style={{width: '100%', height: '100%'}}
                    />
                  </div>
                  <div style={{padding: "10px", backgroundColor: "#eee", borderRadius: "8px", width: "25%", height: "10%"}}>
                    <img
                      data-imgbigurl="img/product/details/product-details-5.jpg"
                      src={img}
                      alt=""
                      style={{width: '100%', height: '100%'}}
                    />
                  </div>
                  <div style={{padding: "10px", backgroundColor: "#eee", borderRadius: "8px", width: "25%", height: "10%"}}>
                    <img
                      data-imgbigurl="img/product/details/product-details-4.jpg"
                      src={img}
                      alt=""
                      style={{width: '100%', height: '100%'}}
                    />
                  </div>
              </div> */}
              <div className="product__details__pic__slider">
                {
                  images && images.map((image, index)=> {
                    return(
                      // <div style={{padding: "10px", backgroundColor: "#eee", borderRadius: "8px", width: "25%", height: "10%"}}>
                      //   <img
                      //     data-imgbigurl="img/product/details/product-details-2.jpg"
                      //     src={`${productsPath}${image.name}`}
                      //     alt=""
                      //     style={{width: '100%', height: '60px', borderRadius: "8px"}}
                      //   />
                      // </div>
                      <div style={{   
                                    borderRadius: "8px", 
                                    width: "25%", 
                                    height: "100%", 
                                    cursor: 'pointer',
                                    backgroundImage: `url(${productsPath}${image.name})`, 
                                    backgroundSize: 'contain', 
                                    backgroundRepeat: 'no-repeat', 
                                    backgroundPosition: 'center'
                                  }}
                          onClick={()=> {setImageChanger(`${productsPath}${image.name}`)}}
                        >                  
                      </div>
                    )
                  })
                }
                
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="product__details__text">
              <h3>{name}</h3>
              <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                <div className="product__details__rating">
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star"></i>
                  <i className="fa fa-star-half-o"></i>
                  <span>(18 reviews)</span>
                </div>
                <div className="product__details__price">
                  {" "}
                  <CurHolder amount={price} curr={curr} /> {cadance}
                </div>
              </div>
              
              <p>{desc}</p>
              {type == "rent" && <RentProdSection {...locState} userData={userData} themeColor={themeColor} lng={lng} />}
              {type == "rentPack" && (
                <RentProdSection {...locState} lng={lng} userData={userData} themeColor={themeColor} packId={packId} />
              )}
              {type == "e-com" && <EcomProdSection {...locState} userData={userData} themeColor={themeColor} lng={lng} />}
              {type == "ecomPack" && (
                <EcomProdSection {...locState} lng={lng} userData={userData} themeColor={themeColor} packId={packId} />
              )}
            </div>
          </div>
        </div>
        <div className="row" style={style.packProdContainer}>
          {/*PACK TAX */}
          {type === "rentPack" || type === "ecomPack" ? (
            <Fragment>
              <PackProdElements lng={lng}>
                {products.map((prod, index) => {
                  return <PackProdItem {...prod} curr={curr} key={index} />;
                })}
              </PackProdElements>
              <div className="col-lg-6"> </div>
              <PackInfoCard
                packInfoArray={packInfoArray}
                curr={curr}
                themeColor={themeColor}
              />
            </Fragment>
            ) : null}
            {/*PROD TAX */}
            {tax ?
              <Fragment>
                <div className="col-lg-6"> </div>

                <PackInfoCard
                  packInfoArray={tax}
                  curr={curr}
                  themeColor={themeColor}
                />
              </Fragment>
              : null}
            {console.log("tax", tax)}
        </div>
      </div>
    </section>
  );
}

export default withRouter(inject("store")(observer(ProductSection)));
