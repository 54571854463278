import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import style from "../utils/style";
import i18n from "../i18n/i18n";
function NavList({ store, location, papa, themeColor }) {
  const lng = store.getLang;
  const userData = store.getUser;
  const access = userData.access;
  console.log("papa", papa);
  return (
    <ul style={style.hamburgerList}>
      {/*<li>
        <Link
          style={{ color: location == "/" ? " #7fad39" : "#252525" }}
          to="/"
          onClick={() => store.changeHamburgerState()}
        >
          {i18n.t("home.label", { lng })}
        </Link>
      </li>*/}
      {access.ecom === 1 &&
        <li>
          <Link
            style={{ color: location == "/e-commerce" ? themeColor : "#252525" }}
            to="/e-commerce"
            onClick={() => store.changeHamburgerState()}
          >
            {i18n.t("eCommerce.label", { lng })}
          </Link>
        </li>
      }
      {access.renting === 1 &&
        <li>
          <Link
            style={{ color: location === "/renting" ? themeColor : "#252525" }}
            to="/renting"
            onClick={() => store.changeHamburgerState()}
          >
            {i18n.t("renting.label", { lng })}
          </Link>
        </li>
      }
      <li>
        <Link
          style={{ color: location === "/contact" ? themeColor : "#252525" }}
          to="/contact"
          onClick={() => store.changeHamburgerState()}
        >
          {i18n.t("contact.label", { lng })}
        </Link>
      </li>
      {/* <li>
          <a href="#">Pages</a>
          <ul class="header__menu__dropdown">
            <li>
              <a href="./shop-details.html">Shop Details</a>
            </li>
            <li>
              <Link to="/shopping-cart">Shopping Cart</Link>
            </li>
            <li>
              <Link to="/checkout">Check Out</Link>
            </li>
            <li>
              <a href="./blog-details.html">Blog Details</a>
            </li>
          </ul>
        </li>*/}
    </ul>
  );
}

export default inject("store")(observer(NavList));
