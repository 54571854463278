const style = {
  liPadding: {
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
  },
  liPaddingSm: {
    paddingTop: ".9rem",
    paddingBottom: ".9rem",
  },
  rFloat: {
    float: "right",
  },
  formGpPadding: {
    padding: "1rem",
  },
  rowPadding: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  card: {
  },
  cardHeader: {
    background: "#fff",
  },
  CardHeaderTxt: {
    fontWeight: 600
  },
  contractTable: {},
  logout: {
    cursor: "pointer",
    color: "#1c1c1c",
  },
  CaroContainer: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  packCard: {
    padding: "1rem",
  },
  contractSectionContainer: {
    paddingTop: 80,
    paddingBottom: 80,
  },
  productTotalSpan: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    justifyContent: "space-around",
    fontWeight: "normal"
  },
  contractItems: {
    contractStart: {
      width: "13rem",
    },
    contractEnd: {
      width: "13rem",
    },
    contractId: {
      width: "5rem"
    }
  },
  //bootstrap btn fix
  bsBtnFix: {
    color: "#fff",
    cursor: "pointer",
  },
  packProdContainer: {
    paddingTop: "4rem",
  },
  bookModalLoaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  headerIconText: {
    color: "#000",
    fontWeight: "bolder",
    letterSpacing: 0.5,
  },
  contactElContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  hamburgerList: {
    margin: 0,
    padding: 0,
    textIndent: 0,
    listStyleType: "none",
    color: "#252525"
  },
  navListColor: { color: "#252525" },
  alertDiv: {
    paddingTop: "1.5rem",
    paddingBottom: "1.7rem",
    fontSize: "1.2rem",
    textAlign: "center",
  },
  authLogo: {
    height: "4rem",
    curosr: "pointer"
  }
};

export default style;
