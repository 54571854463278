import React from "react";
//renders currency and amount here
export default function CurHolder({ amount, curr }) {
  const currency = curr !== undefined ? curr : "";

  const disAmount = parseFloat(amount).toFixed(2); 
  const [whole, decimal] = parseFloat(amount).toFixed(2).split('.'); 
  console.log("amount", amount, disAmount, whole, decimal)
  return (
    <React.Fragment>
      {`${whole}.`} <span style={{ fontSize: "80%", fontWeight: "600" }}>{decimal}{" " + currency}</span>{" "}
    </React.Fragment>
  );
}
