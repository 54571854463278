import React, { useEffect, useState } from "react";
import NavList from "./NavList";
import HeaderLangBar from "./HeaderLangBar";
import { withRouter } from "react-router-dom";
import LoginBar from "./LoginBar";
import { inject, observer } from "mobx-react";
import HeaderIconSection from "./HeaderIconSection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { IoMdCall } from "react-icons/io";
import SearchBar from "./search/SearchBar";
function Header({ location, store, history, fetchings, themeColors, searchCb }) {
  const userData = store.getUser;
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  const multiLang = userData.access.multi_lang;
  const fetching = fetchings; 
  const themeColos = themeColors;
  const searchCbs = searchCb;
  

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  console.log('Scroll Dimension', scrollY)



  return (
    <header className="header" style={{marginTop: "6%"}}>
      <div className={scrollY < 100 ? "header_upper_bar": "header_upper_bar header_upper_scroll"}>
        <div className="header_upper_container">
          <HeaderLangBar langPerms={multiLang} />
          <Link to='/contact' style={{color:"#1c1c1c", fontSize: "14px", textTransform: 'capitalize', fontWeight: '400', display: 'flex', alignItems: 'center', gap: "5px"}}>Contact-us <IoMdCall style={{color: "#1c1c1c", fontSize: '14px'}}/></Link>
        </div>
      </div>
      <div className={scrollY < 100 ?"header__top" : "header__top header_shadow"}>
        {/* <div className="container"> */}
        <div className="header__top__container">
          <div className="row" style={{alignItems: 'center', justifyContent:'space-between'}}>
            <div className="col-lg-3 col-md-3">
              <div className="header__logo" style={{ padding: "5px 0" }}>
                <a onClick={() => history.push("/")}>
                  <img
                    style={{ height: "4rem", cursor: "pointer" }}
                    src={userData.appIcon}
                    alt="company logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <SearchBar searchCb={searchCbs} fetching={fetching} themeColor={themeColos} />
            </div>
            <div className="col-lg-3 col-md-3" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
              <div className="header__top__right" >
                {/* <HeaderLangBar langPerms={multiLang} /> */}
                <LoginBar />
              </div>
              <div className="header__cart">
                <HeaderIconSection themeColor={themeColor} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container header_marge">
        <div className="row">
          <div className="col-lg-3 ">
            <div className="header__logo show-small-screen" style={{ padding: "5px 0" }}>
              <a onClick={() => history.push("e-commerce")}>
                <img
                  style={{ height: "4rem", cursor: "pointer" }}
                  src={userData.appIcon}
                  alt="company logo"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            {/* renting /ecommerce/ Contact  menu here */}
            {/* <nav className="header__menu">
              <NavList location={location.pathname} themeColor={themeColor} />
            </nav> */}

          </div>
          {/* <div className="col-lg-3">
            <div className="header__cart">
              <HeaderIconSection themeColor={themeColor} />
            </div>
          </div> */}
        </div>

        <div
          className="humberger__open"
          onClick={() => store.changeHamburgerState()}
        >
          <i className="fa fa-bars"></i>
        </div>
      </div>
    </header>
  );
}

export default withRouter(inject("store")(observer(Header)));
