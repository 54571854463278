import React, { Fragment } from "react";
import i18n from "../../i18n/i18n";

function ContactForm({ lng, themeColor }) {
  return (
    <Fragment>
      {" "}
      <div className="contact-form spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact__form__title">
                <h2>{i18n.t("leaveMsg.label", { lng })}</h2>
              </div>
            </div>
          </div>
          <form action="#">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <input type="text" placeholder={i18n.t("urName.label", { lng })} />
              </div>
              <div className="col-lg-6 col-md-6">
                <input type="text" placeholder={i18n.t("urEmail.label", { lng })} />
              </div>
              <div className="col-lg-12 text-center">
                <textarea placeholder={i18n.t("urMsg.label", { lng })}></textarea>
                <button type="submit" className="site-btn" style={{ background: themeColor }}>
                  {i18n.t("sendMsg.label", { lng })}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default ContactForm;
