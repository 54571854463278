import React from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Footer from "../Footer";
import BillContent from "./BillContent";

export default function Bill() {
  return (
    <React.Fragment>
      <Hamburger />
      <Header />
      <BillContent>
      </BillContent>


    </React.Fragment>
  );
}
