import React, { useState, useEffect, Fragment } from "react";
import { inject, observer } from "mobx-react";

function HeaderLangBar({ store, defaultLang, langPerms }) {
  const handleLang = (langParam) => {
    //change lang in store
    if (langParam === lang) return;
    store.changeLang(langParam);

    return;
  };
  useEffect(() => {
    //defaultlang is avlb use it here to update state
    //no lang already selected can set default lang from server

    if (langPerms !== 1 && defaultLang) {
      //no lag perms
      //set site lang to whatever the server has set
      handleLang(defaultLang === "eng" ? "en" : defaultLang);
    } else if (langPerms === 1 && defaultLang && lang.length === 0) {
      //we have lang perms
      //if no lang set by user set the default one(he can change it after)
      handleLang(defaultLang === "eng" ? "en" : defaultLang);
    }
  }, [defaultLang])
  const lang = store.getLang;
  return (
    <Fragment>
      {langPerms === 1 &&
        <div className="header__top__right__language">
          <div>{lang === "en" ? "English" : "Français"}</div>
          <span className="arrow_carrot-down"></span>
          <ul>
            <li>
              <span onClick={() => handleLang("fr")}>{lang === "en" ? "French" : "Français"}</span>
            </li>
            <li>
              <span onClick={() => handleLang("en")}>{lang === "en" ? "English" : "Anglais"}</span>
            </li>
          </ul>
        </div>}
    </Fragment>
  );
}
export default inject("store")(observer(HeaderLangBar));
