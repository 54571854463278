import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import { withRouter } from "react-router-dom";
import { serverUrl, postAuth, formatDate, get } from "../../utils/function";
import Spinner from "../Spinner";
import style from "../../utils/style";
import DayPicker, { DateUtils } from "react-day-picker";

const styles = {
  modal: {
    margin: "auto",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
function BookModal({
  prodId,
  prodInfoId,
  price,
  name,
  history,
  img,
  btnTitle,
  totalPrice,
  unitPrice,
  curr,
  userData,
  packId,
  cadance,
  packInfoArray,
  themeColor,
  tax
}) {
  const [range, changeRange] = useState([]);
  const [rangeCal, changeRangeCal] = useState({
    from: undefined,
    to: undefined,
  });
  const [booking, changeBooking] = useState(false);
  const [loading, changeLoading] = useState(false);
  const [currentDate, cCurrentDate] = useState("")
  const [modArr, cModArr] = useState([]);
  const [modStylesArr, cModStylesArr] = useState([])
  const [redDays, cRedDays] = useState([]);
  const [greenDays, cGreenDays] = useState([]);
  const [totalBookingPrice, cTotalBookingPrice] = useState(0);
  useEffect(() => {
    //get current date
    getCurrentDate(userData)
  }, [])
  const getCurrentDate = async (userData) => {
    const url = serverUrl() + "api/customer/renting/get_current_time?";
    const token = userData.acessToken;
    changeLoading(true);

    try {
      const response = await get("GET", url, token);
      cCurrentDate(response.data)
      changeLoading(false);

    } catch (e) {
      console.log(e);
      alert("couldn't get server time");
      changeLoading(false);
    }
    return;
  }

  const validateBooking = () => {
    //decides between pack and prod validating
    if (packId == undefined)
      return validateProductBooking(prodId, prodInfoId, range, userData);

    return validatePackBooking(packId, range, userData);
  };
  const validatePackBooking = async (packId, range, userData) => {
    if (range[0] == undefined || range[1] == undefined) {
      return alert("please select range");
    }
    //handles server side booking price validation
    const url = serverUrl() + "api/customer/renting/calculate_book?";
    const token = userData.acessToken;
    try {
      const response = await postAuth("POST", url, token, {
        pack_id: packId,
        dt_start: range[0],
        dt_end: range[1],
      });
      if (response) {
        const data = response.data;
        console.log("response", response)

        history.push({
          pathname: "/bill",
          state: {
            totalPrice: data.toFixed(2),
            price,
            img,
            name,
            curr,
            dateStart: range[0],
            dateEnd: range[1],
            packId,
            packInfoArray,
            cadance,
            tax,
            client_secret: response.client_secret
          },
        });
      }
    } catch (e) {
      console.log(e);
      alert("couldn't validate booking");
    }
  };

  const validateProductBooking = async (
    prodId,
    prodInfoId,
    range,
    userData
  ) => {
    //make sure user has range selected
    if (range[0] == undefined || range[1] == undefined) {
      return alert("please select range");
    }
    //handles server side booking price validation
    const url = serverUrl() + "api/customer/renting/calculate_book?";
    const token = userData.acessToken;

    try {
      const response = await postAuth("POST", url, token, {
        prod_id: prodId,
        prod_info_id: prodInfoId,
        dt_start: range[0],
        dt_end: range[1],
      });
      if (response) {
        const data = response.data;
        console.log("data", data)
        history.push({
          pathname: "/bill",
          state: {
            totalPrice: data.toFixed(2),
            price,
            img,
            name,
            curr,
            dateStart: range[0],
            dateEnd: range[1],
            prodId,
            prodInfoId,
            cadance,
            tax,
            client_secret: response.client_secret

          },
        });
      }
    } catch (e) {
      console.log(e);
      alert("couldn't validate booking");
    }
  };
  const bookingDetailsCb = (range) => {
    //cb for modal to get booking range back


    changeRange([formatDate(range.from), formatDate(range.to)]);
    changeRangeCal({ from: range.from, to: range.to });
    return;
  };
  const resetRange = () => {
    changeRangeCal({ from: undefined, to: undefined });
  };
  const getBookingCp = (from, to) => {
    changeLoading(true);
    if (packId == undefined)
      return getBooking(prodId, prodInfoId, userData, from, to); //not a pack
    //get packs instead
    return getPackBooking(packId, userData, from, to);
  };

  const getPackBooking = async (packId, userData, from, to) => {
    //get booking when you get it enable model
    const url = serverUrl() + "api/customer/renting/check_packs?";
    const token = userData.acessToken;

    try {
      const response = await postAuth("POST", url, token, {
        pack_id: packId,
        dt_start: from,
        dt_end: to,
      });
      const data = response.data;
      changeBooking(data.availability);
      cTotalBookingPrice(data.price)
      changeLoading(false);
    } catch (e) {
      changeLoading(false);

      console.log(e);
    }
  };
  const getBooking = async (prodId, prodInfoId, userData, from, to) => {
    //get booking when you get it enable model
    const url = serverUrl() + "api/customer/renting/check_booking?";
    const token = userData.acessToken;

    try {
      const response = await postAuth("POST", url, token, {
        prod_id: prodId,
        prod_info_id: prodInfoId,
        dt_start: from,
        dt_end: to,
      });
      const data = response.data;
      changeBooking(data.availability);
      cTotalBookingPrice(data.price)
      changeLoading(false);
    } catch (e) {
      changeLoading(false);

      console.log(e);
    }
    return;
  };
  const handleBookingDisplay = (booking) => {
    //turn bookings array from server into something DayPicker can use

    const modArr = [];
    const modStylesArr = [];
    const redDays = [];
    const greenDays = [];

    //first day
    const firstDayArr = booking[0].date.split("-");
    const firstDay = new Date(
      parseInt(firstDayArr[0]),
      parseInt(firstDayArr[1] - 1),
      parseInt(firstDayArr[2])
    );
    const lastDayArr = booking[booking.length - 1].date.split("-");

    const lastDay = new Date(
      parseInt(lastDayArr[0]),
      parseInt(lastDayArr[1] - 1),
      parseInt(lastDayArr[2])
    );
    booking.forEach((book, index) => {
      ///split 2020-07-15 => 2020 07 15 to use in new Date()
      const dateArr = book.date.split("-");
      let newMod = {
        index: new Date(
          parseInt(dateArr[0]),
          parseInt(dateArr[1] - 1),
          parseInt(dateArr[2])
        ),
      };
      //first day

      let newModStyles = {};
      if (book.qtt > 0) {
        greenDays.push(
          new Date(
            parseInt(dateArr[0]),
            parseInt(dateArr[1] - 1),
            parseInt(dateArr[2])
          )
        );
        newModStyles = {
          //  index: { color: "orange" },
        };
      } else {
        redDays.push(
          new Date(
            parseInt(dateArr[0]),
            parseInt(dateArr[1] - 1),
            parseInt(dateArr[2])
          )
        );
        newModStyles = {
          index: { color: "red" },
        };
      }
      modArr.push(newMod.index);
      modStylesArr.push(newModStyles.index);
    });
    return { modArr, modStylesArr, redDays, greenDays };
  }
  const reselectDays = (redDays, greenDays) => {
    const firstGD = greenDays[0];
    const lastGD = greenDays[greenDays.length - 1];
    const firstRD = redDays[0];
    const lastRD = redDays[redDays.length - 1];
    const day = 86400000; //number of milliseconds in a day
    //get day before first reday
    const beforeFirstRD = new Date(firstRD - day);

    let range = "";
    if (greenDays.length == 0) {
      //no green days tell user there's noth avb
      console.log("nothing avlb ")
      //change price back to zero here
      cTotalBookingPrice(0)
      return bookingDetailsCb(
        {
          from: undefined,
          to: undefined
        })
    }
    if (redDays.length == 0) {
      //no red days everything selected is available
      range = DateUtils.addDayToRange(firstGD, { from: lastGD });

    }
    if (firstGD < firstRD) {
      //select from first green day till last greenDay before the first red day
      range = DateUtils.addDayToRange(firstGD, { from: beforeFirstRD });
    }
    if (firstGD > firstRD) {
      //select from firstGd to last Green day
      range = DateUtils.addDayToRange(lastGD, { from: firstGD });
    }
    return bookingDetailsCb(range);
  }
  useEffect(() => {
    if (booking) {
      const {
        modArr,
        modStylesArr,
        redDays,

        greenDays,
      } = handleBookingDisplay(booking)

      reselectDays(redDays, greenDays);
      cModArr(modArr);
      cModStylesArr(modStylesArr);
      cRedDays(redDays)
      cGreenDays(greenDays)
    }

  }, [booking])

  return (
    <div style={styles.modal}>
      <h2 class="h1" style={{ marginBottom: "1.5rem", fontWeight: "bold" }}>
        {name}
      </h2>
      {loading ? (
        <div style={{ height: "24.5rem" }}>
          <Spinner />
        </div>
      ) : (
          <Calendar
            totalBookingPrice={totalBookingPrice}
            cadance={cadance}
            getBookingCp={getBookingCp}
            booking={booking}
            price={price}
            bookingDetailsCb={bookingDetailsCb}
            totalPrice={totalPrice}
            unitPrice={unitPrice}
            from={rangeCal.from}
            to={rangeCal.to}
            curr={curr}
            currentDate={currentDate}
            redDays={redDays}
            greenDays={greenDays}
            modArr={modArr}
            modStylesArr={modStylesArr}
            themeColor={themeColor}
          />
        )}
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <a
          class="primary-btn"
          style={{ cursor: "pointer", color: "#fff", background: themeColor }}
          onClick={() => validateBooking()}
        >
          {btnTitle}
        </a>
        <a
          class="primary-btn"
          style={{ cursor: "pointer", color: "#fff", background: "darkgrey" }}
          onClick={() => resetRange(prodId, prodInfoId, range)}
        >
          reset{" "}
        </a>
      </div>
    </div>
  );
}
export default withRouter(BookModal);
