import React from "react";
import style from "../utils/style";
export default function Spinner({ height, width, marginRight }) {

  return (
    <div style={style.bookModalLoaderContainer}>
      <div
        className="spinner-border"
        style={{ height, width, marginRight }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
