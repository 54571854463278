import React, { useEffect, useState, Fragment } from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";

import ProductContainer from "../ProductContainer";
import Product from "../renting/Product";
import Pack from "../pack/Pack";
import Error from "../Error";
import { serverUrl, get, procCur, accessRedirect } from "../../utils/function";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";
import Spinner from "../Spinner";
import SearchBar from "../search/SearchBar";
import Pagination from "../pagination/Pagination";
import { withRouter } from "react-router-dom";


function Ecommerce({ store, history }) {
  const [prods, changeProds] = useState([]);
  const [packs, changePacks] = useState([]);
  const [cats, changeCats] = useState([]);
  const [curr, changeCurr] = useState(-1); //symbol or izo of the currencey
  const [activeCatId, changeActiveCatId] = useState(-1); //-1
  const [type] = useState("e-com");
  const [userData, changeUserData] = useState({});
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  const [pageCount, cPageCount] = useState(20);
  const [selectedPage, cSelectedPage] = useState(0);//0 is the first page (1)
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor

  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const userData = store.getUser;
        //redirect if user doesn't have permission to see this page
        const access = userData.access;
        console.log("access.ecom", access.ecom)
        if (access.ecom !== 1) history.push("/renting");

        changeUserData(userData);
      }),
    [] // note empty dependencies
  );
  useEffect(() => {
    //wait untill data is fetched from store to call the api
    if (userData.acessToken !== undefined) getEcomProds(userData);
  }, [userData]);
  useEffect(() => { }, []);
  const getEcomProds = async (userData, page, per_page) => {
    const url = serverUrl() + "api/customer/e-com/get_products?";
    const token = userData.acessToken;

    try {
      const response = await get("GET", url, token, page, per_page);
      console.log("response", response);

      //gracefully fail if requests returns something that's not the data we want
      if (!response) {
        changeSeEr(true);
        changeErMsg(JSON.stringify(response));
        changeLoading(false);

        return;
      }
      const data = response.data;
      cPageCount(data.page_count)
      changeCurr(procCur(data.currency));
      changeProds(data.products);
      changeCats(data.categories);
      changePacks(data.packs);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeSeEr(true);
      changeErMsg("Couldn't reach the server");
      changeLoading(false);
    }
  };
  const filterCb = (id) => {
    //-1 all is active
    changeActiveCatId(id);
  };
  const pageCb = (data) => {
    //returns selected obj with current page - 1 in it
    //get that page
    const page = data.selected + 1;
    cSelectedPage(data.selected);
    changeLoading(true);
    getEcomProds(userData, page);
  }
  console.log('packs :', packs)
  return (
    <Fragment>
      <Hamburger />
      <Header fetchings={loading} themeColors={themeColor} />
      {/* <SearchBar fetching={loading} themeColor={themeColor} /> */}
      {loading ? (
        <div style={{ height: "30rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            <Pack
              userData={userData}
              // packs={packs}
              curr={curr}
              type={type}
              prods={prods}
            />
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <Fragment>
                <ProductContainer
                  cats={cats}
                  filterCb={filterCb}
                  activeCatId={activeCatId}
                  themeColor={themeColor}
                >
                  {prods.map((prod, index) => {
                    const {
                      id,
                      prod_info_id,
                      name,
                      realquantity,
                      description,
                      unitprice,
                      img,
                      category_id,
                      images, 
                      productsPath
                    } = prod;
                    //making link to img
                    const backgroundImg = serverUrl() + img;
                    if (activeCatId === -1) {
                      return (
                        <Product
                          prodId={id}
                          prodInfoId={prod_info_id}
                          quantity={realquantity}
                          name={name}
                          desc={description}
                          price={unitprice}
                          img={backgroundImg}
                          type={type}
                          curr={curr}
                          themeColor={themeColor}
                          key={index}
                          images={images}
                          productsPath={productsPath}
                        />
                      );
                    }
                    if (activeCatId !== -1 && activeCatId === category_id) {
                      return (
                        <Product
                          prodId={id}
                          prodInfoId={prod_info_id}
                          quantity={realquantity}
                          name={name}
                          desc={description}
                          price={unitprice}
                          img={backgroundImg}
                          type={type}
                          curr={curr}
                          themeColor={themeColor}

                          key={index}
                        />
                      );
                    }
                  })}
                </ProductContainer>
                {/*PAGINATION */}
                <div className="container">
                  <Pagination pageCb={pageCb} pageCount={pageCount} selectedPage={selectedPage} themeColor={themeColor} />
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      
    </Fragment>
  );
}

export default withRouter(inject("store")(observer(Ecommerce)));
