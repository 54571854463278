import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import CurHolder from "./CurHolder";
import { procCur } from "../utils/function";
import style from "../utils/style";
import i18n from "../i18n/i18n"; 
import { TfiShoppingCart } from "react-icons/tfi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";


function HeaderIconSection({ store, themeColor }) {

  const prodNum = store.getCartProds
    .map((prod) => prod.prodQt)
    .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);

  const prodTotal = store.getCartProds
    .map((prod) => prod.prodQt * prod.price)
    .reduce((prev, curr) => prev + curr, 0);

  const userData = store.getUser;
  const lng = store.getLang
  const access = userData.access;

  return (
    <Fragment>
      <ul>
        <li>
          <Link to="/contracts">
            {/* <svg
              style={{ height: 18, width: 18 }}
              id="Capa_1"
              data-name="Capa 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 390 512"
            >
              <title>contracts</title>
              <path d="M379.77,73.15l-59.56-55.4A44.82,44.82,0,0,0,300,3.11V90h88.26C386.83,84.54,383.83,77,379.77,73.15Z" />
              <path d="M285,120a15,15,0,0,1-15-15V0H15A15,15,0,0,0,0,15V497a15,15,0,0,0,15,15H375a15,15,0,0,0,15-15V120ZM105,180a45,45,0,0,1-45-45c0-17.95,10.28-35.07,30-42.08V75a15,15,0,0,1,30,0V93a58,58,0,0,1,22.09,13.3,15,15,0,1,1-20.86,21.56C105.95,113,90,121.41,90,135a15,15,0,0,0,15,15,45,45,0,0,1,15.41,87.29c-.14,0-.28,0-.41.07V255a15,15,0,0,1-30,0V237.26c-9.89-3.34-19.36-9.67-27.45-19.3a15,15,0,0,1,23-19.31C101.84,218.14,120,209.79,120,195A15,15,0,0,0,105,180ZM255,452H135a15,15,0,0,1,0-30H255a15,15,0,0,1,0,30Zm60-60H75a15,15,0,0,1,0-30H315a15,15,0,0,1,0,30Zm0-60H75a15,15,0,0,1,0-30H315a15,15,0,0,1,0,30Zm0-62H195a15,15,0,0,1,0-30H315a15,15,0,0,1,0,30Zm0-60H195a15,15,0,0,1,0-30H315a15,15,0,0,1,0,30Z" />
            </svg> */}
            <LiaFileInvoiceDollarSolid style={{fontSize: "25px", color: "#222"}}/>
          </Link>
        </li>

        {access.ecom === 1 ?

          <li>
            <Link to="/shopping-cart">
              <TfiShoppingCart style={{fontSize: "25px", color: "#222"}} /> <span style={{ backgroundColor: themeColor, width: "auto", paddingInline: "4px" }}>{prodNum <= 99 ?prodNum : `${prodNum}+`}</span>
            </Link>
          </li>
          : null}
      </ul>
      {/* Item Price keep it cache for now  custumer will not feel good when he see it  */}
      {/* {access.ecom === 1 ?
        <div className="header__cart__price" style={style.headerIconText}>
          {i18n.t("items.label", { lng })}: <CurHolder amount={prodTotal} curr={procCur(userData.currency)} />
        </div>
        : null} */}

    </Fragment>
  );
}

export default inject("store")(observer(HeaderIconSection));
