import React from "react";
import Popup from "reactjs-popup";
import i18n from "../i18n/i18n";
import styles from "../utils/style"

function AlertModal({ showAlert, changeAlertCb, lng }) {
  return (
    <Popup
      modal
      closeOnDocumentClick
      lockScroll
      open={showAlert}
      position="top center"
      onClose={() => {
        //user closed the thing update state to match
        changeAlertCb();
      }}
      contentStyle={{ backgroundColor: "transparent", border: "" }}
    >
      <div style={styles.alertDiv} className="alert alert-danger" role="alert">
        {i18n.t("alertProd.label", { lng })}
      </div>
    </Popup>
  );
}

export default AlertModal;
