import React from "react";
import { serverUrl, redirectTo } from "../../utils/function";
import CurHolder from "../CurHolder";
import { withRouter } from "react-router-dom";
import style from "../../utils/style";

function PackItem({
  history,
  name,
  img,
  userData,
  packType,
  price,
  desc,
  pack,
  products,
  curr,
  totalBefore,
  discount,
  tax,
  packId,
  cadance
}) {
  const redirectFoo = (hehe = "hehe") => {
    return history.push({
      pathname: "product-page", state: {
        name,
        img,
        price,
        desc,
        curr,
        products,
        type: packType,
        packInfoArray: tax,
        packId,
        cadance
      }
    })
  }
  return (
    <div className="featured__item" style={style.packCard}>
      <div
        className="featured__item__pic set-bg"
        style={{
          backgroundImage: "url(" + img + ")",
          backgroundSize: "contain",
          cursor: "pointer",
          backgroundPosition: "center"
          //clip:"rect(0px,115px,115px,0px)"
        }}
        onClick={() =>
          redirectFoo()
        }
      ></div>
      <div className="featured__item__text" style={{ paddingTop: "-5rem" }}>
        <h6>
          <span
            onClick={() =>
              redirectFoo()
            }
            style={{ cursor: "pointer" }}
          >
            {" "}
            {name}
          </span>
        </h6>

        <h5>
          {" "}
          <CurHolder amount={price} curr={curr} />{" "}
        </h5>
      </div>
    </div>
  );
}
export default withRouter(PackItem);
