import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderLangBar from "../HeaderLangBar";
import i18n from "../../i18n/i18n"
import style from "../../utils/style"

function AuthHeader({ lng, logo, appName, defaultLang, langPerms }) {
  const [show, cShow] = useState(true)

  const classOne = show ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
  const classTwo = show ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div className="container">
        <img src={logo} style={style.authLogo}></img>
        <a className="navbar-brand" style={{ marginRight: "auto", marginLeft: "1rem" }}>{!appName ? "Ctrli-Ecom" : appName}</a>
        <button
          className={`${classTwo}`}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          onClick={() => cShow(!show)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>


        <div className={`${classOne}`} style={{ flexGrow: 0 }} id="navbarSupportedContent">


          <HeaderLangBar defaultLang={defaultLang} langPerms={langPerms} />
          {console.log("-----------------------------", langPerms)}

          <ul className="navbar-nav mr-auto"></ul>

          <ul className="navbar-nav ml-auto">

            <li className="nav-item">
              <Link className="nav-link" to="/login">
                {i18n.t("login.label", { lng })}

              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/register">
                {i18n.t("register.label", { lng })}

              </Link>
            </li>
            {/*   <li className="nav-item dropdown">
              <a
                id="navbarDropdown"
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                v-pre
              >
                name<span class="caret"></span>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <a class="dropdown-item">logout </a>

                <form id="logout-form" method="POST"></form>
              </div>
  </li>*/}
          </ul>
        </div>
      </div>
    </nav >
  );
}

export default AuthHeader;
