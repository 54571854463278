import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

function SearchBar({ userData, history, location, searchCb, fetching, themeColor }) {
  console.log("themeColor", themeColor)
  const [keyword, changeKeyword] = useState("search");
  const redirectSearchPage = (keyword) => {
    //if in search page callback with new query else redirect to search page with state
    if (location.pathname === "/search") return searchCb(keyword);
    return history.push({
      pathname: "/search",
      state: {
        keyword,
      },
    });
  };
  return (
    // <div className="container" >
      // <div className="row" >
        <div
          className="hero__search"
          style={{ margin: "auto"}}
        >
          <div className="hero__search__form">
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                placeholder={
                  location.state !== undefined
                    ? location.state.keyword
                    : "search"
                }
                onChange={(e) => changeKeyword(e.target.value)}
              />
              <button
                className="site-btn"
                style={{ backgroundColor: themeColor, minWidth: '15%', alignItems: 'center', 
                        justifyContent: 'center', height: '80%', display: 'flex', 
                        borderRadius: 20
                      }}
                onClick={() =>{if(keyword.length > 0 && keyword !== "search"){redirectSearchPage(keyword);}}}
                disabled={fetching}
              >
                <IoSearch style={{fontSize: "25px", textAlign: 'center'}} />
                {/* search */}
              </button>
            </form>
          </div>
        </div>
      // </div>
    // {/* </div> */}
  );
}

export default withRouter(SearchBar);
