import React, { useEffect, useState, Fragment } from "react";
import { serverUrl, post, setNavIcon, get } from "../../utils/function";
import AuthHeader from "./AuthHeader";
import { Redirect, Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import i18n from "../../i18n/i18n"
import Wrapper from "./Wrapper";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

function ForgotPass({ history, store }) {
    const [email, changeEmail] = useState("");
    const [loading, cLoading] = useState(true)
    const [themeColor, cThemeColor] = useState("");
    const [logo, cLogo] = useState("");
    const [appName, cAppName] = useState("");
    const [feedback, cFeedback] = useState(false);
    const [fbStatus, cFbStatus] = useState(true)
    const [feedbackMsg, cFeedbackMsg] = useState("");
    const [navIcon, cNavIcon] = useState("");

    const lng = store.getLang;
    useEffect(() => {
        getTheme()
    }

        , [])


    useEffect(() => {
        if (navIcon.length > 0) store.changeNavIcon(navIcon);
    }, [navIcon])
    const sendEmailReset = async () => {
        cLoading(true);

        const url = serverUrl() + "api/customer/restpassword?";

        try {
            const response = await post("POST", url, { email: email });
            cFeedback(true);
            if (response.status === 200) {
                cFeedbackMsg("Link sent to your email")
                cFbStatus(true)
            }
            else {
                cFeedbackMsg(response.data)
                cFbStatus(false)
            }
            cLoading(false);
            console.log("response", response)
        } catch (e) {
            cFeedback(true);

            cFeedbackMsg("Something went wrong, your request didn't make it :(")
            cFbStatus(false)
            cLoading(false);

        }
    }
    const getTheme = async () => {
        const url = serverUrl() + "api/customer/get_theme_color?";

        try {
            const response = await get("GET", url);
            //gracefully fail if requests returns something that's not the data we want

            const { theme_color, app_img, app_name, renting_icon, ecom_icon } = response.data;

            cThemeColor(theme_color);
            cLogo(app_img);
            cAppName(app_name)
            setNavIcon(cNavIcon, renting_icon, ecom_icon)
            cLoading(false);
        } catch (e) {
            console.log(e);
            const userData = store.getUser;
            cLoading(false);
            cThemeColor(userData.defaultThemeColor);
        }

    };
    const handleChange = (e) => {
        const value = e.target.value;
        console.log("value", value)
        return changeEmail(value);

    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        sendEmailReset()

    };

    return (

        <Wrapper themeColor={themeColor}>
            {loading ?
                <div style={{ height: "45rem" }}><Spinner /></div> :
                <Fragment>
                    <AuthHeader lng={lng} logo={logo} appName={appName} />

                    <main className="py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="card">
                                        <div className="card-header">{i18n.t("resetPassword.label", { lng })}</div>

                                        <div className="card-body">
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className="form-group row">
                                                    <label
                                                        htmlFor="email"
                                                        className="col-md-4 col-form-label text-md-right"
                                                    >
                                                        {i18n.t("emailAdress.label", { lng })}
                                                    </label>

                                                    <div className="col-md-6">
                                                        <input
                                                            id="email"
                                                            type="email"
                                                            className="ogani-input form-control "
                                                            name="email"
                                                            required
                                                            autoFocus
                                                            value={email}
                                                            onChange={handleChange}
                                                        />

                                                        <span className="invalid-feedback" role="alert">
                                                            <strong>msg</strong>
                                                        </span>

                                                        <span className={fbStatus ? "valid-feedback" : "invalid-feedback"} style={{ display: feedback ? "inline-block" : "none", paddingTop: ".5rem", paddingBottom: "-1rem" }}>
                                                            <strong>{feedbackMsg}</strong>
                                                        </span>
                                                    </div>
                                                </div>



                                                <div className="form-group row">
                                                    <div className="col-md-6 offset-md-4">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="remember"
                                                                id="remember"

                                                            />


                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-0">
                                                    <div className="col-md-8 offset-md-4">
                                                        <button id="auth-btn" className="btn btn-primary">
                                                            {i18n.t("reset.label", { lng })}

                                                        </button>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Helmet>
                        {<link rel="icon" href={navIcon} />}
                    </Helmet>
                </Fragment>}
        </Wrapper>)
}

export default withRouter(inject("store")(observer(ForgotPass)));
