import React from "react";
import moment from "moment";
import CurrHolder from "../CurHolder";
import Helmet from "react-helmet";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { formatDate, calculateBooking } from "../../utils/function";
//extending date object to add a month
Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};
const styles = {
  priceP: {
    margin: 0,
    marginLeft: "1.2rem",
    fontSize: "1.2rem",
  },
};
export default class Calendar extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.state = {
      modArr: [],
      modStylesArr: [],
      price: 0,
      totalPrice: 0,
      redDays: [],
      firstDay: "",
      lastDay: "",

    };
    this.dateRestricted = true;
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.getDays = this.getDays.bind(this);
    this.selectedRedDay = this.selectedRedDay.bind(this);
    this.handleBookingRequest = this.handleBookingRequest.bind(this);
    this.getCurrentDate = this.getCurrentDate.bind(this);
    this.addMonth = this.addMonth.bind(this)
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }
  getCurrentDate(dateString) {
    const newDstring = dateString.split(" ")[0];

    return formatDate(new Date(newDstring));
  }
  handleBookingRequest(from, to) {
    let fixedFrom = "";
    let fixedTo = "";
    //if usser selects later dates first
    if (from > to) {
      fixedFrom = to;
      fixedTo = from;
    } else {
      fixedFrom = from;
      fixedTo = to;
    }

    const frmtFrom = formatDate(fixedFrom),
      frmtTo = formatDate(fixedTo);

    this.props.getBookingCp(frmtFrom, frmtTo);
    return;
  }
  selectedRedDay(from, to, redDays) {
    //tells u if u selected a redday or not
    const firstRedDay = redDays[0];
    const lastRedDay = redDays[redDays.length - 1];

    return from < firstRedDay && to > lastRedDay;
  }
  handleDayClick(day, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }
    const { from, to } = this.props;
    //if to and from selected and user clicks again start over again
    let range = "";
    console.log("from: ", from, "to: ", to)
    if (to) {
      range = DateUtils.addDayToRange(day, {});

      if (this.dateRestricted && day) {

        //date restriction is on select the last day for the user(month later)
        const dayCopy = new Date(day);
        const to = this.addMonth(dayCopy)

        this.handleBookingRequest(day, to);

      }
      /*if(this.dateRestricted)
        const from = this.addMonth(1)  
      */
    } else {
      //first click
      range = DateUtils.addDayToRange(day, { from, to });
      if (this.dateRestricted && day) {

        //date restriction is on select the last day for the user(month later)
        const dayCopy = new Date(day);
        const to = this.addMonth(dayCopy)

        this.handleBookingRequest(day, to);

      }
      //get booking for selected peroid if no date restriction
      if (from && !this.dateRestricted) {
        this.handleBookingRequest(from, day);
      }


    }
    /* if (this.selectedRedDay(range.from, range.to, this.state.redDays)) {
      alert("can not select red days");
      return;
    }*/
    //tell parent about range changes
    this.props.bookingDetailsCb(range); //[formatDate(range.from), formatDate(range.to)]);
    //this.props.ReturnRange(range.from, range.to);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  getDays(from, to) {
    //get days between starta nd finish
    if (from == undefined || to == undefined) return;
    const fromArr = formatDate(from).split("-");
    const toArr = formatDate(to).split("-");

    let future = moment(`${toArr[1]}/${toArr[2]}/${toArr[0]}`);
    let start = moment(`${fromArr[1]}/${fromArr[2]}/${fromArr[0]}`);
    let d = future.diff(start, "days"); // 9
    return d;
  }
  addMonth(today) {
    //adds one month to a perticular date
    return today.addMonths(1);
  }

  render() {
    const { totalBookingPrice, price,
      totalPrice, unitPrice,
      curr, from, to, currentDate,
      booking, redDay, modStylesArr,
      modArr, cadance, themeColor } = this.props;
    const modifiers = {
      ...modArr,
      start: from,
      end: to,
    };
    const modifiersStyles = {
      ...modStylesArr,
    };

    return (
      <div className="RangeExample">

        <p style={styles.priceP}>
          <span className="font-weight-bold text-dark">{unitPrice}:</span>{" "}
          <CurrHolder amount={price} curr={curr} /> {cadance}
        </p>
        <p style={styles.priceP}>
          <span className="font-weight-bold text-dark">{totalPrice}:</span>{" "}
          <CurrHolder
            amount={totalBookingPrice}
            curr={curr}
          />
        </p>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          // month
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
          selectedDays={[from, { from, to }]}
          onDayClick={this.handleDayClick}
          disabledDays={

            {
              before: new Date(this.getCurrentDate(currentDate)), //disable everyhting before today
            }

          }
        />
        <Helmet>
          <style>{`
   .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
   
    background-color:${themeColor} !important;
  }
    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color:rgba(127, 173, 57, .08) !important;
      color: ${themeColor};
    }
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;

    }
    .Selectable .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;

    }
    .Selectable .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .DayPicker-Day--today {
      color: inherit!important;
    }
  `}</style>
        </Helmet>
      </div>
    );
  }
}
/*
  <p>

          {!from && !to && "Please select the first day."}
          {from && !to && "Please select the last day."}
          {from &&
            to &&
            `Selected from ${from.toLocaleDateString()} to
                  ${to.toLocaleDateString()}`}{" "}
          {from && to && (
            <button className="link" onClick={this.handleResetClick}>
              Reset
            </button>
          )}
        </p>
export default function Calendar() {
  const modifiers = {
    active: {
      from: new Date(2018, 0, 12),
      to: new Date(2018, 0, 16),
    },
    // active: {daysOfWeek: [1]},
    inactive: {
      rom: new Date(2018, 0, 12),
      to: new Date(2018, 0, 16),
      from: new Date(2018, 0, 17),
      to: new Date(2018, 0, 19),
    },
    birthday: new Date(2018, 9, 30),
  };
  const modifiersStyles = {
    birthday: {
      color: "white",
      backgroundColor: "#ffc107",
    },
    active: {
      color: "white",
      backgroundColor: "green",
    },
    inactive: {
      color: "white",
      backgroundColor: "red",
    },
  };
  const hurrdurr = new Date(2018, 0, 12);
  const hurrdurrStyle = {
    color: "white",
    backgroundColor: "red",
  };
  modifiersStyles.hurrdurr = hurrdurrStyle;
  modifiers.hurrdurr = hurrdurr;
  console.log(modifiers);
  return (
    <DayPicker
      month={new Date(2018, 0)}
      modifiers={modifiers}
      modifiersStyles={modifiersStyles}
    />
  );
}
*/
