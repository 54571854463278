import React, { useState, useEffect, Fragment } from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Footer from "../Footer";
import ContractSection from "./ContractSection";
import { autorun } from "mobx";
import { inject, observer } from "mobx-react";
import { serverUrl, get, procCur } from "../../utils/function";
import ContractItem from "./ContractItem";
import Spinner from "../Spinner";
import Error from "../Error";
function Contracts({ store }) {
  const [userData, changeUserData] = useState({});
  const [contracts, changeContracts] = useState([]);
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const userData = store.getUser;
        changeUserData(userData);
      }),
    [] // note empty dependencies
  );
  useEffect(() => {
    //wait untill data is fetched from store to call the api
    if (userData.acessToken != undefined) getContracts(userData);
  }, [userData]);
  const getContracts = async (userData) => {
    const url = serverUrl() + "api/customer/renting/contracts?";
    const token = userData.acessToken;
    try {
      let response = await get("GET", url, token);
      //reverse response here
      //check if we have a valid array first;
      const descRes = [...response.data].reverse()

      changeContracts(descRes);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeSeEr(true);
      changeErMsg("Couldn't reach the server");
      changeLoading(false);
    }
    return;
  };
  const lng = store.getLang;
  return (
    <Fragment>
      <Hamburger />
      <Header />
      {loading ? (
        <div style={{ height: "30rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <ContractSection lng={lng}>
                {contracts.map((cont, index) => {
                  const { id, DateTimeStart, DateTImeEnd, state_now, name, price, currency } = cont;
                  return (
                    <ContractItem
                      id={id}
                      name={name}
                      totalPrice={price}
                      dateStart={DateTimeStart}
                      dateEnd={DateTImeEnd}
                      stateNow={state_now}
                      curr={procCur(currency)}
                      key={index}
                    />
                  );
                })}
              </ContractSection>
            )}
          </Fragment>
        )}
    </Fragment>
  );
}
export default inject("store")(observer(Contracts));
