import {
  decorate,
  observable,
  action,
  computed,
  toJS,
  isObservableObject,
} from "mobx";
//toJS => mobxobservable to plain obj or array...
/*
var obj = mobx.observable({
    x: 1
})

var clone = mobx.toJS(obj)

console.log(mobx.isObservableObject(obj)) // true
console.log(mobx.isObservableObject(clone)) // false */
class Store {
  lang = "";
  //token to use in api requests
  accessToken = "";
  email = "";
  actorId = "";
  companyApp = "";
  hamburgerOn = false;
  appIcon = "";
  access = {};
  currency = {};
  themeColor = "";
  defaultThemeColor = "#7fad39";
  appLoaded = false;
  navIcon = "";
  //products stored in cart
  cartProds = [];
  // actions
  changeNavIcon = (navIcon) => {
    this.navIcon = navIcon;
    //save navIcon to localstorage
    localStorage.setItem("navIcon", JSON.stringify(navIcon));
  }
  updateCartProd = (prodId, prodQt) => {
    //update qtt in prod arraye
    const prods = this.cartProds;
    const updateProd = prods.filter((prod) => prod.prodId == prodId)[0];
    const indexOfProd = prods.findIndex((prod) => prod.prodId == prodId);
    if (indexOfProd == -1) return; //item not found return
    const newProd = { ...updateProd, prodQt };
    this.cartProds[indexOfProd] = newProd;
  };
  addCartProds = (data) => {
    this.cartProds = [...this.cartProds, data];
  };
  removeCartProds = (prodId) => {

    const newProds = this.cartProds.filter((prod) => prod.prodId != prodId);
    this.cartProds = newProds;
    console.log(newProds);
    return;
  };
  changeHamburgerState = () => {
    //toggle hamburger
    this.hamburgerOn = !this.hamburgerOn;
  };
  changeLang = (lang) => {
    this.lang = lang;
    //save lang to localstorage
    localStorage.setItem("lang", JSON.stringify(lang));

  };
  loginUser = (data) => {
    const { email, actorId, companyApp, accessToken, appIcon, currency, access, themeColor, publishableStripeKey } = data;
    //save user creds to localstorage
    localStorage.setItem("userData", JSON.stringify(data));
    this.email = email;
    this.actorId = actorId;
    this.companyApp = companyApp;
    this.accessToken = accessToken;
    this.appIcon = appIcon;
    this.currency = currency;
    this.access = access;
    this.themeColor = themeColor;
  };
  changeAppLoaded() {
    this.appLoaded = !this.appLoaded;
  }
  //getters
  get getNavIcon() {
    return this.navIcon;
  }
  get getCartProds() {
    return this.cartProds;
  }
  get getHamburgerState() {
    return this.hamburgerOn;
  }
  get getLang() {
    return this.lang;
  }
  get getUser() {
    const userData = {
      email: this.email,
      actorId: this.actorId,
      companyApp: this.companyApp,
      acessToken: this.accessToken,
      appIcon: this.appIcon,
      currency: this.currency,
      access: this.access,
      themeColor: this.themeColor,
      defaultThemeColor: this.defaultThemeColor
    };
    return userData;
  }
  get getAppLoaded() {
    return this.appLoaded;
  }
}

decorate(Store, {
  //obvs:
  lang: observable,
  email: observable,
  actorId: observable,
  companyApp: observable,
  accessToken: observable,
  access: observable,
  hamburgerOn: observable,
  cartProds: observable,
  themeColor: observable,
  defaultThemeColor: observable,
  appLoaded: observable,
  navIcon: observable,
  //----
  //actions:
  changeLang: action,
  loginUser: action,
  changeHamburgerState: action,
  addCartProds: action,
  removeCartProds: action,
  changeAppLoaded: action,
  changeNavIcon: action,
  //--------
  //computed:
  getLang: computed,
  getUser: computed,
  getHamburgerState: computed,
  getAppLoaded: computed,
  getNavIcon: computed
  //--------
});

export default new Store();
