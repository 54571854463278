import React, { useEffect } from "react";
import { inject, observer, } from "mobx-react";

import { accessRedirect } from "./utils/function";
import { withRouter } from 'react-router-dom';
import { autorun } from "mobx";
function RedirectRoute({ store, history }) {
    React.useEffect(
        () =>
            autorun(() => {
                //get data from store and set it to state
                const userData = store.getUser;
                const userDataLocal = localStorage.getItem("userData");

                if (typeof userData.access === "undefined" || userDataLocal === null) return history.push("login");
                else accessRedirect(history, userData.access)

            }),
        [] // note empty dependencies
    );


    return (
        <div></div>
    )
}

export default inject("store")(observer(withRouter(RedirectRoute)))