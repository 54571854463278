import React from "react";
import BreadCrumb from "./Breadcrumb";
import CheckoutSection from "./CheckoutSection";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Hero from "../Hero";
function Checkout() {
  return (
    <React.Fragment>
      {/*<Loader />*/}

      <Hamburger />
      <Header />
      <Hero />
      <BreadCrumb />
      <CheckoutSection />
    </React.Fragment>
  );
}

export default Checkout;
