import React from "react";
import CurHolder from "../CurHolder";
import { PercentToValue } from "../../utils/function";
import style from "../../utils/style";
import i18n from "../../i18n/i18n";

function PackInfoCard({ packInfoArray, curr, price, lng, themeColor }) {
  /*const disAm = PercentToValue(discount, totalBefore);
  const amAfterDis = parseInt(disAm) + totalBefore
  const taxAmount = PercentToValue(tax, amAfterDis);
;*/
  return (
    <div className={"col-lg-5"}>
      <div class="shoping__checkout">
        <ul>
          {packInfoArray.map((item) => {
            return <li>
              {item.name + " "}{item.val && item.val}
              <span style={style.productTotalSpan}>
                <CurHolder amount={item.total} curr={curr} />
              </span>
            </li>
          })}
        </ul>
        <a class="primary-btn" style={{ background: themeColor, ...style.bsBtnFix }} onClick={() => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }}>
          {i18n.t("book.label", { lng })}
        </a>
      </div>
    </div>
  );
}

export default PackInfoCard;
