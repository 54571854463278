import React from "react";
import CurHolder from "./CurHolder";
import style from "../utils/style";
export default function ProdListSummary({
  prodSumArr,
  title,
  btnTxt,
  colSpan,
  themeColor,
  onclick,
}) {
  // [{"title","amount", "curr" ]
  //title
  return (
    <div className={"col-lg-" + colSpan}>
      <div className="shoping__checkout">
        <h5> {title}</h5>
        <ul>
          {prodSumArr.map((sum, index) => {
            const { title, amount, curr } = sum;
            return (
              <li key={index}>
                {title}{" "}
                <span style={style.productTotalSpan}>
                  {" "}
                  <CurHolder
                    amount={amount === "" ? 0 : amount}
                    curr={curr === "" ? 0 : curr}
                  />
                </span>
              </li>
            );
          })}
        </ul>
        <button
          onClick={onclick}
          className="primary-btn"
          style={{ background: themeColor, borderRadius: "5px", width: '100%', fontSize: '18px', fontWeight: '600' }}
        >
          {btnTxt}
        </button>
      </div>
    </div>
  );
}
