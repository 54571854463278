import React, { Fragment } from "react";
import i18n from "../../i18n/i18n";

import ContactForm from "./ContactForm";
import Map from "./Map";
import UserInfoSection from "./UserInfoSection";
export default function ContactSection({
  phoneArr,
  adrsArr,
  mailArr,
  gprs,
  noData,
  lng,
  themeColor
}) {
  return (
    <Fragment>
      {!noData ? (
        <Fragment>
          <UserInfoSection
            themeColor={themeColor}
            phoneArr={phoneArr}
            adrsArr={adrsArr}
            mailArr={mailArr}
            lng={lng}
          />
          <Map gprs={gprs} />
          <ContactForm lng={lng} themeColor={themeColor} />
        </Fragment>
      ) : (
          <ContactForm lng={lng} />
        )}
    </Fragment>
  );
}
