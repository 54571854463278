import React, {useState} from "react";
import Table from "../Table";
import style from "../../utils/style";
import i18n from "../../i18n/i18n";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import ProdListSummary from "../ProdListSummary";
import swal from 'sweetalert' ;
import { serverUrl, post, postAuth, accessRedirect, get, setNavIcon } from "../../utils/function";
import moment from 'moment';
import { autorun } from "mobx";
import { withRouter } from "react-router-dom";

//{i18n.t("bill.label", { lng })}
function ShoppingSection({ children, curr, totalPrice, store, history }) {
  const lng = store.getLang;
  const ProdSum = [
    { title: "Subtotal", amount: totalPrice, curr },
    { title: "Total", amount: totalPrice, curr },
  ];
  const userData = store.getUser;
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  const [ products, setProducts] = useState(store.getCartProds);

  React.useEffect(
    () =>
      autorun(() => {
        
      }),
    [] // note empty dependencies
  );

  const orderGenerate = async () => {
    swal({
      title: i18n.t("warningShoppingMsg.label", { lng }),
      text: i18n.t("totalAmount.label", { lng }) + totalPrice,
      icon: "warning",
      buttons: [i18n.t("Cancel.label", { lng }), i18n.t("OK.label", { lng })],
      dangerMode: true,
    })
    .then(async (ifConfirm) => {
      if (ifConfirm) {
        const url = serverUrl() + "api/customer/bill/store?";
        const token = userData.acessToken;
        let prices = [];
        let qtts = [];
        let prodIds = [];
        let prodInfoIds = [];
        await products.map((prod, index) => {
          console.log('maps ', prod.price)
          prices.push(prod.price)
          qtts.push(prod.prodQt)
          prodIds.push(prod.prodId)
          prodInfoIds.push(prod.prodInfoId)
        })
        const data = {
          billId: null,
          billSettings: null,
          itemsNbr: products.length,
          type: 1,
          level: 2,
          futureLevel: null,
          actorId: userData.actorId,
          billDesc: null,
          cdate: null,
          billPaymentMethod: null,
          deliverytime: null,
          currency_id: userData.currency.id,
          ratio: null,
          price_: prices,
          calculated_quantity_with_original_mesure_: qtts,
          prodId_: prodIds,
          prodInfoId_: prodInfoIds,
          taxesNbr: 2,
          taxeId_: [],
          taxeType_: [2,2],
          taxeName_: ['TOTAL HT', 'TOTAL TTC'],
          taxeValue_: [],
          request_api: true
        };
        console.log(data)
        try {
          const responseData = await postAuth("POST", url, token, data);
          
          console.log("responseData", responseData)
          history.push("/e-commerce");
          
        } catch (e) {
          console.log(e);
          alert("couldn't reach the server");
        }
      }
    });
  }

  return (
    <section className="shoping-cart spad">
      <div className="container">
        <Table
          children={children}
          thArr={[
            i18n.t("products.label", { lng }),
            i18n.t("price.label", { lng }),
            i18n.t("quantity.label", { lng }),
            i18n.t("total.label", { lng }),
          ]}
        />
        <div className="row">
          <div className="col-lg-12">
            <div className="shoping__cart__btns">
              <Link
                style={{ ...style.bsBtnFix, color: "grey", borderRadius: "6px" }}
                className="primary-btn cart-btn"
                to="/e-commerce"
              >
                {i18n.t("conShopping.label", { lng })}
              </Link>
            </div>
          </div>
          {/* <div class="col-lg-6">
            <div class="shoping__continue">
              <div class="shoping__discount">
                <h5>Discount Codes</h5>
                <form action="#">
                  <input type="text" placeholder="Enter your coupon code" />
                  <button class="site-btn">APPLY COUPON</button>
                </form>
              </div>
            </div>
      
          </div>  */}
        <div style={{width: "100%", alignItems: 'center', justifyContent:'flex-end', display: 'flex'}}>
          <ProdListSummary
              prodSumArr={ProdSum}
              title={i18n.t("cartTotal.label", { lng })}
              btnTxt={i18n.t("proceedCheckout.label", { lng })}
              colSpan={6}
              themeColor={themeColor}
              onclick={orderGenerate}
          />
        </div>
          
        </div>
      </div>
    </section>
  );
}

export default withRouter(inject("store")(observer(ShoppingSection)));
