import React, { Fragment } from "react";

function Map({ gprs }) {

  return (
    <Fragment>
      {gprs.long != undefined ? (
        <div className="map">
          <iframe
            src={
              "https://maps.google.com/maps?q=" +
              gprs.long +
              "," +
              gprs.lat +
              "&t=&z=15&ie=UTF8&iwloc=&output=embed"
            }
            height="500"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
          {/* <div className="map-inside">
            <i className="icon_pin"></i>
            <div className="inside-widget">
              <h4>New York</h4>
              <ul>
                <li>Phone: +12-345-6789</li>
                <li>Add: 16 Creek Ave. Farmingdale, NY</li>
              </ul>
            </div>
  </div>*/}
        </div>
      ) : null}
    </Fragment>
  );
}

export default Map;
