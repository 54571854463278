import React, { Fragment, useState, useEffect } from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import SearchSection from "./SearchSection";
import SearchBar from "./SearchBar";
import { inject, observer } from "mobx-react";
import { serverUrl, postAuth, procCur } from "../../utils/function";

import { autorun } from "mobx";
import { withRouter } from "react-router-dom";
import Product from "../renting/Product";
import Spinner from "../Spinner";
import Error from "../Error";
function Search({ store, location }) {
  const [userData, changeUserData] = useState({});
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  const [searchData, changeSearchData] = useState([]);
  const [filter, changeFilter] = useState(-1);
  const filterCb = (value) => {
    return changeFilter(value === "all" ? -1 : value);
  };
  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const userData = store.getUser;
        changeUserData(userData);
      }),
    [] // note empty dependencies
  );
  useEffect(() => {
    //wait untill data is fetched from store to call the api
    const passedParams = location.state;
    if (userData.acessToken != undefined && passedParams != undefined) {
      queryProds(userData, passedParams.keyword);
    }
  }, [userData]);
  const flattenResults = (response) => {
    const ecom = response.ecom == undefined ? [] : response.ecom;
    const renting = response.renting == undefined ? [] : response.renting;
    const packs = response.packs == undefined ? [] : response.packs;

    ecom.forEach((prod) => {
      prod.filterType = "e-com";
    });
    renting.forEach((prod) => {
      prod.filterType = "rent";
    });
    packs.forEach((prod) => {
      prod.filterType = "pack";
    });

    return [...ecom, ...renting, ...packs];
  };
  const queryProds = async (userData, keyword) => {
    const url = serverUrl() + "api/customer/search_by_name?";
    const token = userData.acessToken;
    try {
      const response = await postAuth("POST", url, token, { keyword: keyword });
      //gracefully fail if requests returns something that's not the data we want
      if (response.data === undefined) {
        changeSeEr(true);
        changeErMsg(JSON.stringify(response));
        changeLoading(false);

        return;
      }
      const data = flattenResults(response.data);
      changeSearchData(data);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeSeEr(true);
      changeErMsg("Couldn't reach the server");
      changeLoading(false);
    }
  };
  const searchCb = (keyword) => {
    changeLoading(true);
    queryProds(userData, keyword);
  };
  console.log("userData", userData)
  return (
    <Fragment>
      <Hamburger />
      <Header  searchCb={searchCb} themeColors={userData.themeColor ? userData.themeColor : userData.defaultThemeColor}/>
      {/* <SearchBar searchCb={searchCb} themeColor={userData.themeColor ? userData.themeColor : userData.defaultThemeColor} /> */}
      {loading ? (
        <div style={{ height: "30rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <SearchSection searchData={searchData} filterCb={filterCb}>
                {searchData.map((item, index) => {
                  const {
                    id,
                    prod_info_id,
                    name,
                    realquantity,
                    description,
                    unitprice,
                    img,
                    filterType,
                  } = item;
                  const backgroundImg = serverUrl() + img;
                  console.log('Data value __:', backgroundImg);

                  if (filter === -1) {
                    return (
                      <Product
                        prodId={id}
                        prodInfoId={prod_info_id}
                        quantity={realquantity}
                        name={name}
                        desc={description}
                        price={unitprice}
                        img={backgroundImg}
                        type={filterType}
                        curr={procCur(userData.currency)}
                        userData={userData}
                        key={index}
                      />
                    );
                  }
                  if (filter === filterType) {
                    return (
                      <Product
                        prodId={id}
                        prodInfoId={prod_info_id}
                        quantitiy={realquantity}
                        name={name}
                        desc={description}
                        price={unitprice}
                        img={backgroundImg}
                        type={filterType}
                        curr={procCur(userData.currency)}
                        userData={userData}
                        key={index}
                      />
                    );
                  }
                })}
              </SearchSection>
            )}
          </Fragment>
        )}
    </Fragment>
  );
}

export default inject("store")(observer(withRouter(Search)));
