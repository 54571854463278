import React, { useEffect, useState, Fragment } from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Footer from "../Footer";
import ProductContainer from "../ProductContainer";
import Product from "./Product";
import Error from "../Error";
import Pack from "../pack/Pack";
import { serverUrl, get, procCur } from "../../utils/function";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";
import Spinner from "../Spinner";
import SearchBar from "../search/SearchBar";
import Pagination from "../pagination/Pagination"
import { withRouter } from "react-router-dom";
function Renting({ store, history }) {
  const [rentProds, changeRentProds] = useState([]);
  const [packs, changePacks] = useState([]);
  const [cats, changeCats] = useState([]);
  const [activeCatId, changeActiveCatId] = useState(-1);
  const [curr, changeCurr] = useState(-1); //symbol or izo of the currencey
  const [type] = useState("rent");
  const [userData, changeUserData] = useState({});
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  const [pageCount, cPageCount] = useState(20);
  const [selectedPage, cSelectedPage] = useState(0);//0 is the first page (1)
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const userData = store.getUser;
        //redirect if user doesn't have permission to see this page
        const access = userData.access;
        if (access.renting !== 1) history.push("/e-commerce");
        changeUserData(userData);
      }),
    [] // note empty dependencies
  );
  useEffect(() => {
    //wait untill data is fetched from store to call the api
    if (userData.acessToken !== undefined) getRentProds(userData);
  }, [userData]);

  const getRentProds = async (userData, page, per_page) => {
    const url = serverUrl() + "api/customer/renting/products?";
    // const token = sotre.get
    const token = userData.acessToken;
    try {
      const response = await get("GET", url, token, page, per_page);
      //gracefully fail if requests returns something that's not the data we want
      if (!response) {
        changeSeEr(true);
        changeErMsg(JSON.stringify(response));
        changeLoading(false);

        return;
      }

      const data = response.data;
      cPageCount(data.page_count)
      changeCurr(procCur(data.currency));
      changeRentProds(data.products);
      changeCats(data.categories);
      changePacks(data.packs);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeSeEr(true);
      changeErMsg("Couldn't reach the server");
      changeLoading(false);
    }
  };
  const filterCb = (id) => {
    //-1 all is active
    changeActiveCatId(id);
  };

  const pageCb = (data) => {
    //returns selected obj with current page - 1 in it
    //get that page
    const page = data.selected + 1;
    cSelectedPage(data.selected);
    changeLoading(true);
    getRentProds(userData, page);

  }
  return (
    <Fragment>
      <Hamburger />
      <Header />
      <SearchBar fetching={loading} themeColor={userData.themeColor ? userData.themeColor : userData.defaultThemeColor} />

      {loading ? (
        <div style={{ height: "30rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            <Pack
              userData={userData}
              packs={packs}
              curr={curr}
              type={type}
              prods={rentProds}
            />
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <Fragment>
                <ProductContainer
                  cats={cats}
                  filterCb={filterCb}
                  activeCatId={activeCatId}
                  themeColor={themeColor}
                >
                  {rentProds.map((prod, index) => {
                    const {
                      id,
                      prod_info_id,
                      name,
                      realquantity,
                      description,
                      unitprice,
                      img,
                      category_id,
                      cadance,
                      tax
                    } = prod;
                    //making link to img
                    const backgroundImg = serverUrl() + img;
                    if (activeCatId == -1) {
                      return (
                        <Product
                          prodId={id}
                          prodInfoId={prod_info_id}
                          quantitiy={realquantity}
                          name={name}
                          desc={description}
                          price={unitprice}
                          img={backgroundImg}
                          type={type}
                          curr={curr}
                          userData={userData}
                          cadance={cadance}
                          themeColor={themeColor}
                          tax={tax}
                          key={index}
                        />
                      );
                    }
                    if (activeCatId != -1 && activeCatId == category_id) {
                      return (
                        <Product
                          prodId={id}
                          prodInfoId={prod_info_id}
                          quantitiy={realquantity}
                          name={name}
                          desc={description}
                          price={unitprice}
                          img={backgroundImg}
                          type={type}
                          curr={curr}
                          userData={userData}
                          themeColor={themeColor}
                          tax={tax}
                          key={index}
                        />
                      );
                    }
                  })}
                </ProductContainer>
                {/*PAGINATION */}
                <div class="container">
                  <Pagination pageCb={pageCb} pageCount={pageCount} selectedPage={selectedPage} themeColor={themeColor} />
                </div>

              </Fragment>
            )}

          </Fragment>
        )}


    </Fragment>
  );
}
export default withRouter(inject("store")(observer(Renting)));
