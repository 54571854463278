import React from "react";
import QunInput from "../QunInput";
import CurrHolder from "../CurHolder";

export default function ShoppingCartItem({
  prodId,
  name,
  price,
  desc,
  curr,
  img,
  prodQt,
  removeItemCb,
  updateItemCb,
  quantity,
}) {
  const qtCb = (value) => {
    updateItemCb(prodId, value);
  };
  return (
    <tr>
      <td class="shoping__cart__item">
        <img
          src={img}
          alt=""
          style={{ maxHeight: "3rem", marginBottom: "1rem" }}
        />
        <div style={{ display: "inline-block" }}>
          <h5>{name}</h5>
          <p>{desc}</p>
        </div>
      </td>
      <td class="shoping__cart__price">
        <CurrHolder amount={price} curr={curr} />
      </td>
      <td class="shoping__cart__quantity">
        <QunInput quantity={quantity} prodQt={prodQt} qtCb={qtCb} />
      </td>
      <td class="shoping__cart__total">
        <CurrHolder amount={price * prodQt} curr={curr} />
      </td>
      <td class="shoping__cart__item__close">
        <span class="icon_close" onClick={() => removeItemCb(prodId)}></span>
      </td>
    </tr>
  );
}
