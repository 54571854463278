import React from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Footer from "../Footer";
import ProductSection from "../ProductSection";
export default function ProductPage() {
  return (
    <React.Fragment>
      <Hamburger />
      <Header />
      <ProductSection />
    </React.Fragment>
  );
}
