import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import './pagination.css';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";

function Pagination({ pageCount, pageCb, selectedPage, themeColor }) {
    console.log("page Count", pageCount)

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-device-width: 1224px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    const isTabletOrMobileDevice = useMediaQuery({
        query: "(max-device-width: 1224px)",
    });


    const getPagCount = () => {
        if (isDesktopOrLaptop || isBigScreen) return 10;
        if (isTabletOrMobile || isTabletOrMobileDevice) return 2;
        return;
    }
    const getPagWidth = () => {
        if (isDesktopOrLaptop || isBigScreen) return "50rem";
        if (isTabletOrMobile || isTabletOrMobileDevice) return "inherit";
        return;
    }
    const getArrowDis = () => {
        if (isDesktopOrLaptop || isBigScreen) return "";
        if (isTabletOrMobile || isTabletOrMobileDevice) return "none";
        return;
    }

    return (
        <div
            style={pageCount>1 ?{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 20,
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
            }:{
                display: 'none'
            }}
        >

            <ReactPaginate
                //set curr page using props
                forcePage={selectedPage}

                activeClassName={'item pag-active '}
                breakClassName={'item break-me '}
                breakLabel={'...'}
                containerClassName={'pagination'}
                disabledClassName={'disabled-page'}
                marginPagesDisplayed={1}
                nextClassName={"item next "}
                previousLabel={<svg width="15" height="15" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.04 492"><title>front-arrow</title><path d="M198.61,246.1,382.66,62a27,27,0,0,0,0-38.06L366.54,7.86a27,27,0,0,0-38.06,0L109.33,227a27.15,27.15,0,0,0,0,38.19L328.27,484.14a27,27,0,0,0,38.07,0L382.46,468a27,27,0,0,0,0-38.06Z" transform="translate(-101.48 0)" /></svg>
                }
                onPageChange={pageCb}
                pageCount={pageCount}
                pageClassName={'item pagination-page '}
                pageRangeDisplayed={getPagCount()}
                previousClassName={"item previous"}
                nextLabel={<svg width="15" height="15" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.04 492"><title>front-arrow</title><path d="M293.39,245.9,109.34,430a27,27,0,0,0,0,38.06l16.12,16.12a27,27,0,0,0,38.06,0L382.67,265a27.15,27.15,0,0,0,0-38.19L163.73,7.86a27,27,0,0,0-38.07,0L109.54,24a27,27,0,0,0,0,38.06Z" transform="translate(-101.48 0)" /></svg>
                }
            />
            <Helmet>
                <style>{`.pag-active {
                            border: solid 1px ${themeColor};
                            border-radius: 40px;
                            background:${themeColor};
                            color: #fff;
                                    }
                        .active > a:focus {
                             /* fixes issue with text turning white on click*/
                             color:${themeColor};
                                            }
                                            .pagination {
                                                align-items: center;
                                                display: flex;
                                                flex-direction: row;
                                                height: 60px;
                                                justify-content: center;
                                                list-style-type: none;
                                                position: relative;
                                                width: ${getPagWidth()};
                                                margin: auto;
                                            }
                                            .next {
                                                display: ${getArrowDis()};
                                                fill: rgba(0, 0, 0, 0.801);
                                                position: absolute;
                                                right: 3rem;
                                            }
                                            .previous {
                                                display: ${getArrowDis()};
                                                fill: rgba(0, 0, 0, 0.801);
                                                position: absolute;
                                                left:3rem;
                                            }
                                            
                                            
  `}</style>
            </Helmet>
        </div>
    );
}

export default Pagination;
