import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import style from "../utils/style";
import { FaRegUser } from "react-icons/fa";


// Import a pre-configured instance of i18next
import i18n from "../i18n/i18n";
function LoginBar({ store, history }) {
  const logout = () => {
    //delete current user data
    localStorage.removeItem("userData");
    //redirect to login
    history.push({
      pathname: "/login",
    });
    return;
  };
  const lng = store.getLang;
  const userData = store.getUser;
  return (
    <div className="header__top__right__auth" >
      {userData.email !== undefined && userData.email.length > 0 ? (
        <React.Fragment>
          <p style={{ margin: 0, paddingRight: "10px" }}>
            <span style={style.logout} onClick={() => logout()}>
              <FaRegUser style={{color: '#222', fontSize: '25px'}}/>
              {/* {userData.email} */}
            </span>
          </p>
        </React.Fragment>
      ) : (
          <Link to="/login">
            {" "}
            <i className="fa fa-user"></i> {i18n.t("login.label", { lng })}
          </Link>
        )}
      {/* <a href="#"></a> */}
    </div>
  );
}

export default withRouter(inject("store")(observer(LoginBar)));
