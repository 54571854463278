import React, { useState } from "react";
import Popup from "reactjs-popup";
import BookModal from "./BookModal";
import AlertModal from "../AlertModal";
import i18n from "../../i18n/i18n";

import { useMediaQuery } from "react-responsive";

function RentProdSection({
  lng,
  prodId,
  prodInfoId,
  name,
  price,
  img,
  curr,
  userData,
  packId, //packId
  cadance,
  packInfoArray,
  themeColor,
  tax
}) {
  const [open, changeOpen] = useState(false);
  const [showAlert, changeShowAlert] = useState(false);

  const changeAlertCb = () => {
    changeShowAlert(false);
    return;
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  return (
    <React.Fragment>
      <a
        class="primary-btn"
        onClick={() => {
          if (price != 0) {
            //open modal
            changeOpen(!open);
          } else {
            changeShowAlert(true);
          }
        }}
        style={{
          color: "#fff",
          cursor: "pointer",
          backgroundColor: themeColor
        }}
      >
        {i18n.t("book.label", { lng })}
      </a>
      <AlertModal showAlert={showAlert} changeAlertCb={changeAlertCb} />
      <Popup
        modal
        closeOnDocumentClick
        lockScroll
        open={open}
        contentStyle={
          isTabletOrMobile || isTabletOrMobileDevice
            ? {
              width: "100vw",
              height: "80vh",
              overflow: "auto",
            }
            : {}
        }
        onClose={() => {
          //user closed the thing update state to match
          changeOpen(false);
        }}
      >
        <BookModal
          packInfoArray={packInfoArray}
          cadance={cadance}
          prodId={prodId}
          prodInfoId={prodInfoId}
          price={price}
          name={name}
          img={img}
          curr={curr}
          packId={packId}//packId
          userData={userData}
          btnTitle={i18n.t("rent.label", { lng })}
          totalPrice={i18n.t("totalPrice.label", { lng })}
          unitPrice={i18n.t("unitPrice.label", { lng })}
          themeColor={themeColor}
          tax={tax}
        />
      </Popup>
      <a href="#" class="heart-icon">
        <span class="icon_heart_alt"></span>
      </a>{" "}
    </React.Fragment>
  );
}
export default RentProdSection;
