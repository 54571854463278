import React, { useState, useEffect, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { post, serverUrl, accessRedirect, get, setNavIcon } from '../../utils/function';
import AuthHeader from './AuthHeader';
import Spinner from '../Spinner';
import i18n from "../../i18n/i18n";
import Wrapper from './Wrapper';
import { inject, observer } from "mobx-react";
import Error from '../Error';
import { Helmet } from "react-helmet";

function PasswordReset({ history, location, store }) {
    const [password, cPassword] = useState("");
    const [conPassword, cConPassword] = useState("");
    const [email, cEmail] = useState("");
    const [token, cToken] = useState("");
    const [loading, cLoading] = useState(true);
    const [themeColor, cThemeColor] = useState("");
    const [logo, cLogo] = useState("");
    const [appName, cAppName] = useState("");
    const [error, cError] = useState(false);
    const [feedback, cFeedback] = useState(false);
    const [fbStatus, cFbStatus] = useState(true)
    const [feedbackMsg, cFeedbackMsg] = useState("");
    const [navIcon, cNavIcon] = useState("");

    const lng = store.getLang;

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const email = urlParams.get('email');
        const token = urlParams.get('token');

        cEmail(email);
        cToken(token);
        if (token && email) validateToken(token, email);
        else {
            cError(true);
            cLoading(false);
        }
    }, [])

    useEffect(() => {
        if (navIcon.length > 0) store.changeNavIcon(navIcon);
    }, [navIcon])
    const getTheme = async () => {
        const url = serverUrl() + "api/customer/get_theme_color?";

        try {
            const response = await get("GET", url);
            //gracefully fail if requests returns something that's not the data we want

            const { theme_color, app_img, app_name, renting_icon, ecom_icon } = response.data;

            cThemeColor(theme_color);
            cLogo(app_img);
            cAppName(app_name)
            setNavIcon(cNavIcon, renting_icon, ecom_icon)

            cLoading(false);
        } catch (e) {
            console.log(e);
            const userData = store.getUser;
            cLoading(false);

            cThemeColor(userData.defaultThemeColor);
        }
    };

    const validateToken = async (token, email) => {
        const url = serverUrl() + "api/customer/validateresetpassword?";

        try {
            const response = await post("POST", url, { token, email });

            if (response.status === 200) getTheme();
            else {
                cLoading(false);
                cError(true);
            };

        } catch (e) {
            console.log(e);
            cError(true);
            cLoading(false);

        }
    }
    const handleChange = (e) => {
        const value = e.target.value;
        console.log("e.target.name", e.target.name)
        if (e.target.name === "password") cPassword(value);
        if (e.target.name === "conPassword") cConPassword(value);
        return;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = serverUrl() + "api/customer/resetprocesspassword?";
        const data = {
            email,
            password,
            token,
            password_confirmation: conPassword,
        };
        //check to see if pass = conPass
        if (password !== conPassword) return alert("passwords don't match")
        if (password.length < 6 || conPassword.length < 6) return alert("password needs to be longer")
        try {
            const response = await post("POST", url, data);
            if (response.status !== 200) {
                cFeedback(true);
                cFeedbackMsg("Couldn't reset your password, try again")
                cFbStatus(false)
            } else {
                cFeedback(true);
                cFeedbackMsg("Password changed, logging you in");
                cFbStatus(true)
                login(email, password)
            }
            //login user
        } catch (e) {
            console.log(e);
            cFeedback(true);
            cFeedbackMsg("Something went wrong")
            cFbStatus(false)
        }
    };
    const login = async (email, password) => {
        const url = serverUrl() + "api/customer/login?";
        const data = {
            username: email,
            password: password,
            type: "client",
        };
        try {
            const responseData = await post("POST", url, data);
            //login success redirect
            if (responseData) {
                const data = {
                    accessToken: responseData.access_token,
                    email: email,
                    actorId: responseData.actorId,
                    companyApp: responseData.company_app,
                    appIcon: responseData.app_icon,
                    currency: responseData.currency,
                    access: responseData.access,
                    themeColor: responseData.theme_color,
                    publishableStripeKey: responseData.stripe_public

                };

                //save user data to store
                store.loginUser(data);
                //redirect to appropriate page
                accessRedirect(history, responseData.access);

            }
            //else alert fail
            else alert("couldn't login, check your creds");
            console.log(responseData);
        } catch (e) {
            console.log(e);
            alert("couldn't reach the server");
        }
    };

    return (
        <Wrapper themeColor={themeColor}>
            {loading ?
                <div style={{ height: "45rem" }}><Spinner /></div> :
                <Fragment>
                    {error ? <Error ErMsg="Something went wrong :("></Error> :
                        <Fragment>

                            <AuthHeader lng={lng} logo={logo} appName={appName} />
                            <main className="py-4">
                                <div className="container">
                                    <div className="row justify-content-center">


                                        <div className="col-md-8">
                                            <div className="card">
                                                <div className="card-header">{i18n.t("login.label", { lng })}</div>

                                                <div className="card-body">
                                                    <form onSubmit={(e) => handleSubmit(e)}>


                                                        <div className="form-group row">
                                                            <label
                                                                htmlFor="password"
                                                                className="col-md-4 col-form-label text-md-right"
                                                            >
                                                                {i18n.t("password.label", { lng })}

                                                            </label>

                                                            <div className="col-md-6">
                                                                <input
                                                                    id="password"
                                                                    type="password"
                                                                    className="ogani-input form-control "
                                                                    name="password"
                                                                    required
                                                                    autoComplete="current-password"
                                                                    value={password}
                                                                    onChange={handleChange}
                                                                />

                                                                <span className="invalid-feedback" role="alert">
                                                                    <strong>msg</strong>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label
                                                                htmlFor="conPassword"
                                                                className="col-md-4 col-form-label text-md-right"
                                                            >
                                                                {i18n.t("confirmPassword.label", { lng })}

                                                            </label>

                                                            <div className="col-md-6">
                                                                <input
                                                                    id="conPassword"
                                                                    type="password"
                                                                    className="ogani-input form-control "
                                                                    name="conPassword"
                                                                    required
                                                                    autoComplete="current-password"
                                                                    value={conPassword}
                                                                    onChange={handleChange}
                                                                />

                                                                <span className="invalid-feedback" role="alert">
                                                                    <strong>msg</strong>
                                                                </span>

                                                                <span className={fbStatus ? "valid-feedback" : "invalid-feedback"} style={{ display: feedback ? "inline-block" : "none", paddingTop: ".5rem", paddingBottom: "-1rem" }}>
                                                                    <strong>{feedbackMsg}</strong>
                                                                </span>
                                                            </div>
                                                        </div>



                                                        <div className="form-group row mb-0">
                                                            <div className="col-md-8 offset-md-4">
                                                                <button id="auth-btn" className="btn btn-primary" style={{ background: themeColor }}
                                                                >
                                                                    {i18n.t("reset.label", { lng })}

                                                                </button>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </main>
                            <Helmet>
                                {<link rel="icon" href={navIcon} />}
                            </Helmet>
                        </Fragment>}

                </Fragment>}
        </Wrapper>)
}

export default withRouter(inject("store")(observer(PasswordReset)));
