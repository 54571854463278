import React, { useEffect, useState } from "react";
import Hamburger from "../Hamburger";
import Header from "../Header";
import Footer from "../Footer";
import ShoppingSection from "./ShoppingSection";
import { inject, observer } from "mobx-react";
import ShoppingCartItem from "./ShoppingCartItem";
import { autorun } from "mobx";

function ShoppingCart({ store }) {
  const [totalPrice, changeTotalPrice] = useState("");
  const [curr, changeCurr] = useState("");
  const [prodArr, changeProdArr] = useState([]);
  React.useEffect(
    () =>
      autorun(() => {
        //get prods from store
        //get total price
        //if there are any products else kept it at zero
        const prodArr = store.getCartProds;
        if (prodArr.length < 1) return;
        calcTotalCartPricce(prodArr);
      }),
    [] // note empty dependencies
  );
  const calcTotalCartPricce = (prodArr) => {
    const curr = prodArr[0].curr;
    const totalCartPrice = prodArr
      .map((prod) => prod.price * prod.prodQt)
      .reduce((accum, current) => accum + current);
    changeTotalPrice(totalCartPrice);
    changeCurr(curr);
    changeProdArr(prodArr);
  };

  const removeItemCb = (prodId) => {
    // const newProds = prodArr.filter((prod) => prod.prodId != prodId);
    store.removeCartProds(prodId);
    return;
  };
  const updateItemCb = (prodId, prodQt) => {
    store.updateCartProd(prodId, prodQt);
    return;
  };

  return (
    <React.Fragment>
      <Hamburger />
      <Header />
      <ShoppingSection totalPrice={totalPrice} curr={curr}>
        {prodArr.map((prod, index) => {
          return (
            <ShoppingCartItem
              {...prod}
              removeItemCb={removeItemCb}
              updateItemCb={updateItemCb}
              key={index}
            />
          );
        })}
      </ShoppingSection>
    </React.Fragment>
  );
}

export default inject("store")(observer(ShoppingCart));
