import React, { useState, useEffect, Fragment } from "react";
import CarouselContainer from "./CarouselContainer";
import PackItem from "./PackItem";
import { serverUrl, get, procCur } from "../../utils/function";
import Spinner from "../Spinner";
import Error from "../Error";
export default function Pack({ curr, history, type, userData }) {
  const [packs, changePacks] = useState([]);
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  const packType = type === "rent" ? "rentPack" : "ecomPack";

  const getPacks = async (userData) => {
    // const url = serverUrl() + "api/customer/renting/get_packs?";
    const url = serverUrl() + "api/customer/e-com/get_packs?";
    // const token = sotre.get
    const token = userData.acessToken;
    try {
      const response = await get("GET", url, token);
      //gracefully fail if requests r eturns something that's not the data we want
      if (!response) {
        changeSeEr(true);
        changeErMsg(JSON.stringify(response));
        changeLoading(false);
      }
      /* if (response.currency == undefined) {
    

        return;
      }*/
      changePacks(response.data);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeSeEr(true);
      changeErMsg("Couldn't reach server");
      changeLoading(false);
    }
    return;
  };
  useEffect(() => {
    getPacks(userData);
  }, []);
  console.log('packs  :', packs);
  return (
    <Fragment>
      {loading ? (
        <div style={{ height: "20rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <CarouselContainer>
                {packs.map((pack, index) => {
                  const {
                    name,
                    img,
                    amount,
                    des,
                    tax,
                    total_net,
                    discount,
                    id,
                    products,
                    cadance
                  } = pack.pack;
                  return (
                    <PackItem
                      name={name}
                      img={serverUrl() + img}
                      price={amount}
                      desc={des}
                      tax={tax}
                      products={products}
                      packType={packType}
                      userData={userData}
                      curr={curr}
                      packId={id}
                      cadance={cadance}
                      key={index}
                    />
                  );
                })}
              </CarouselContainer>
            )}
          </Fragment>
        )}
    </Fragment>
  );
}
