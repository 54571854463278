import i18next from "i18next";

i18next.init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: "en" || "fr", // 'en' | 'es'
  // Using simple hardcoded resources for simple example
  resources: {
    fr: {
      translation: {
        name: { label: "Nom" },
        home: { label: "accueil" },
        eCommerce: { label: "e-commerce" },
        renting: { label: "louer" },
        login: { label: "S'identifier" },
        billDet: { label: "Détails de la facture" },
        creditCart: { label: "Carte de crédit" },
        pay: { label: "payer" },
        bill: { label: "Facture" },
        cancel: { label: "annuler" },
        changeDate: { label: "changer la date" },
        rent: { label: "louer" },
        book: { label: "réserver" },
        unitPrice: { label: "Prix ​​unitaire" },
        totalPrice: { label: "Prix ​​total " },
        reset: { label: "reset" },
        alertProd: { label: "Ce produit n'est pas disponible pour le moment." },
        addToCart: { label: "Ajouter au panier" },
        viewInCart: { label: "Dans votre panier" },
        products: { label: "Produits" },
        price: { label: "Prix" },
        quantity: { label: "Quantité" },
        total: { label: "Total" },
        cartTotal: { label: "Total Du Panier" },
        proceedCheckout: { label: "PASSER À LA CAISSE" },
        conShopping: { label: "CONTINUER VOS ACHATS" },
        contact: { label: "contacter" },
        items: { label: "articles" },
        discount: { label: "Remise" },
        tax: { label: "Impôt" },
        totalNet: { label: "Total Net" },
        start: { label: "Début" },
        end: { label: "Fin" },
        status: { label: "Statut" },
        phone: { label: "Téléphone" },
        address: { label: "Addresse" },
        email: { label: "Email" },
        leaveMsg: { label: "Laissez Un Message" },
        sendMsg: { label: "envoyer le message" },
        urName: { label: "Votre Nom" },
        urEmail: { label: "Votre Email" },
        urMsg: { label: "Votre Message" },
        password: { label: "mot de passe" },
        emailAdress: { label: "E-Mail Addresse" },
        register: { label: "s’inscrire" },
        firstName: { label: "prénom" },
        lastName: { label: "nom de famille" },
        phoneNumber: { label: "numéro de téléphone" },
        confirmPassword: { label: "confirmer le mot de passe" },
        forgotPassword: { label: "mot de passe oublié?" },
        resetPassword: { label: " réinitialiser le mot de passe" },
        reset: { label: "Réinitialiser" },
        warningShoppingMsg: { label: "Voulez-vous continuer à acheter?" },
        totalAmount: { label: "Montant total " },
        OK: { label: "OK" },
        Cancel: { label: "Annuler" },

      },
    },
    en: {
      translation: {
        name: { label: "Name" },

        home: { label: "home" },
        eCommerce: { label: "e-commerce" },
        renting: { label: "renting" },
        login: { label: "Login" },
        billDet: { label: "Bill details" },
        creditCart: { label: "Credit card" },
        bill: { label: "Bill" },
        pay: { label: "pay" },
        cancel: { label: "cancel" },
        changeDate: { label: "change the date" },
        rent: { label: "rent" },
        book: { label: "book" },
        unitPrice: { label: "Unit price" },
        totalPrice: { label: "Total price" },
        reset: { label: "reset" },
        alertProd: { label: "This product is not available at the moment." },
        addToCart: { label: "add to cart" },
        viewInCart: { label: "view in cart" },
        products: { label: "Products" },
        price: { label: "Price" },
        quantity: { label: "Quantity" },
        total: { label: "Total" },
        cartTotal: { label: "Cart Total" },
        proceedCheckout: { label: "PROCEED TO CHECKOUT" },
        conShopping: { label: "CONTINUE SHOPPING" },
        contact: { label: "contact" },
        items: { label: "items" },
        discount: { label: "Discount" },
        tax: { label: "Tax" },
        totalNet: { label: "Net Total" },
        start: { label: "Start" },
        end: { label: "End" },
        status: { label: "Status" },
        phone: { label: "Phone" },
        address: { label: "Address" },
        email: { label: "Email" },
        leaveMsg: { label: "Leave Message" },
        sendMsg: { label: "Send message" },
        urName: { label: "Your Name" },
        urEmail: { label: "Your Email" },
        urMsg: { label: "Your Message" },

        password: { label: "password" },
        emailAdress: { label: "Address E-Mail " },
        register: { label: "register" },
        firstName: { label: "First Name" },
        lastName: { label: "last name" },
        phoneNumber: { label: "phone number" },
        confirmPassword: { label: "confirm password" },
        forgotPassword: { label: "forgot your password?" },
        resetPassword: { label: "reset password" },
        reset: { label: "reset" },
        warningShoppingMsg: {label: "Do you want to continue buying?"},
        totalAmount: {label: "Total amount "},
        OK: { label: "OK" },
        Cancel: { label: "Cancel" },

      },
    },
  },
});

export default i18next;
