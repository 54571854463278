import React from "react";

function SearchSection({ children, filterCb }) {
  console.log("children", children[0].props);
  return (
    <div className="container" style={{ marginTop: "2rem" }}>
      <div className="row">
        <div className="filter__item" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="filter__sort">
                <span>Sort By</span>
                <select
                  className="form-control"
                  style={{
                    border: "none",
                    width: "10rem",
                    display: "inline",
                    marginLeft: "1rem",
                  }}
                  onChange={(e) => filterCb(e.target.value)}
                >
                  <option value="all" defaultValue>
                    All
                  </option>

                  <option value="rent">Renting</option>
                  <option value="e-com">E-commerce</option>
                </select>
              </div>
            </div>
            <div className="col-lg-4 col-md-4"></div>
            <div className="col-lg-4 col-md-4">
              <div className="filter__found">
                <h6>
                  <span>{children.length}</span> Products found
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row featured__filter">{children}</div>
    </div>
  );
}

export default SearchSection;
