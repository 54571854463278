import React, { useEffect, useState, Fragment } from "react";
import ContactSection from "./ContactSection";
import Hamburger from "../Hamburger";
import Header from "../Header";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";
import { serverUrl, get } from "../../utils/function";
import Spinner from "../Spinner";
import Error from "../Error";
function Contact({ store }) {
  const [userData, changeUserData] = useState({});
  const [phoneArr, changePhArr] = useState([]);
  const [mailArr, changeMailArr] = useState([]);
  const [adrsArr, changeAdrsArr] = useState([]);
  const [gprs, changeGprs] = useState({});
  const [serverEr, changeSeEr] = useState(false);
  const [ErMsg, changeErMsg] = useState("");
  const [loading, changeLoading] = useState(true);
  const [noData, changeNoData] = useState(false);
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor

  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const userData = store.getUser;
        changeUserData(userData);
      }),
    [] // note empty dependencies
  );
  const parseContacts = (response) => {
    const phoneArr = [];
    const adrsArr = [];
    const mailArr = [];
    const gprs = {};
    response.forEach((info, index) => {
      if (info.type == "address") return adrsArr.push(info);
      if (info.type == "email") return mailArr.push(info);
      if (info.type == "phone") return phoneArr.push(info);
      if (info.type == "gprs") {
        const gprsArr = info.info.split(",");
        gprs.long = gprsArr[0];
        gprs.lat = gprsArr[1];
      }
    });
    return { phoneArr, adrsArr, mailArr, gprs };
  };
  useEffect(() => {
    //wait untill data is fetched from store to call the api
    if (userData.acessToken != undefined) getContactInfo(userData);
  }, [userData]);
  const getContactInfo = async (userData) => {
    const url = serverUrl() + "api/customer/actor/get_info?";
    // const token = sotre.get
    const token = userData.acessToken;
    try {
      const response = await get("GET", url, token);
      //gracefully fail if requests returns something that's not the data we want
      if (response.state != 200) {
        changeSeEr(true);
        changeErMsg(JSON.stringify(response));
        changeLoading(false);

        return;
      }
      //if response empty tell
      if (response.data.length == 0) changeNoData(true);
      const { phoneArr, adrsArr, mailArr, gprs } = parseContacts(response.data);
      changePhArr(phoneArr);
      changeAdrsArr(adrsArr);
      changeMailArr(mailArr);
      changeGprs(gprs);
      changeLoading(false);
    } catch (e) {
      console.log(e);
      changeLoading(false);
      changeSeEr(true);
      changeErMsg("Couldn't reach the server");
    }
  };
  const lng = store.getLang;
  return (
    <Fragment>
      <Hamburger />
      <Header />
      {loading ? (
        <div style={{ height: "30rem" }}>
          <Spinner />
        </div>
      ) : (
          <Fragment>
            {serverEr && <Error ErMsg={ErMsg} />}
            {!serverEr && (
              <ContactSection
                phoneArr={phoneArr}
                mailArr={mailArr}
                adrsArr={adrsArr}
                gprs={gprs}
                noData={noData}
                lng={lng}
                themeColor={themeColor}
              />
            )}
          </Fragment>
        )}
    </Fragment>
  );
}
export default inject("store")(observer(Contact));
