import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { withRouter } from "react-router-dom";
import style from "../../utils/style";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
function CarouselContainer({
  children,
  packs,
  prods,
  curr,
  history,
  type,
  userData,
}) {
  console.log("children",children)
  return (
    <div className="container">
      <div style={style.CaroContainer}>
        <Carousel responsive={responsive} infinite={true} showDots>
          {children}
        </Carousel>
      </div>
    </div>
  );
}

export default withRouter(CarouselContainer);
