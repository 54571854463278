import React, { useEffect } from "react";
import NavList from "./NavList";
import HeaderLangBar from "./HeaderLangBar";
import { withRouter } from "react-router-dom";
import LoginBar from "./LoginBar";
import { inject, observer } from "mobx-react";
import HeaderIconSection from "./HeaderIconSection";
import SearchBar from "./search/SearchBar";

function Hamburger({ location, store }) {
  const hmSt = store.getHamburgerState;
  const userData = store.getUser;
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  const multiLang = userData.access.multi_lang;

  return (
    <React.Fragment>
      <div
        style={{ zIndex: 1001 }}
        className={
          hmSt ? "humberger__menu__overlay active" : "humberger__menu__overlay"
        }
        onClick={() => store.changeHamburgerState()}
      ></div>
      <div style={{ zIndex: 1002 }}
        className={
          hmSt
            ? "humberger__menu__wrapper show__humberger__menu__wrapper"
            : "humberger__menu__wrapper"
        }
      >
        <div className="humberger__menu__logo">
          <a href="#">
            <img style={{ height: "2.5rem" }} src={userData.appIcon} alt="" />{" "}
            {hmSt}
          </a>
        </div>
        <div className="humberger__menu__cart">
          <LoginBar />
          <HeaderIconSection themeColor={themeColor} />
        </div>
        {/* <div className="humberger__menu__widget">
          <HeaderLangBar multiLang={multiLang} />
          <LoginBar />
        </div> */}
        {/* <nav className="humberger__menu__nav mobile-menu">
          <NavList location={location.pathname} papa="hamburger" themeColor={themeColor} />
        </nav>
        <div id="mobile-menu-wrap" >
          <NavList location={location.pathname} papa="hamburger" themeColor={themeColor} />
        </div> */}
        <div className="header__top__right__social">
          {/* <a href="#">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="#">
            <i className="fa fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fa fa-linkedin"></i>
          </a>
          <a href="#">
            <i className="fa fa-pinterest-p"></i>
          </a>
      */}
        </div>
        <div className="humberger__menu__contact">
          {/*<ul>
            <li>
              <i className="fa fa-envelope"></i> {userData.email}
            </li>
            <li>Free Shipping for all Order of $99</li>
          </ul>*/}
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(inject("store")(observer(Hamburger)));
