import React, { useState } from "react";

export default function QunInput({ quantity, prodQt, qtCb, disabled }) {
  const [quantit, setQuantity] = useState(prodQt);
  const handelDicriment = () => {
    setQuantity(prevCount => prevCount -1); 
    qtCb(prodQt - 1)
  };
  const handelIncriment = () => {
    setQuantity(prevCount => prevCount +1);
    qtCb(prodQt + 1) 
  };
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= quantity) {
      setQuantity(value);
      qtCb(value);
    }
  };
  console.log('rrrrr', quantity, prodQt, qtCb, disabled)

  return (
    <div style={{display: "flex", alignItems: 'center', gap: 10}}>
        {/* <select
        className="custom-select"
        style={{ width: "4rem" }}
        onChange={(e) => {qtCb(e.target.value); console.log('------------------->', e.target.value)}}
        disabled={disabled}
      > */}
        {/* make array 1...quantity and render elements */}
        {/* {quantity > 0 &&
          [...Array(parseInt(quantity)).keys()].map((num) => {
            return (
              <option value={num + 1} selected={prodQt === num + 1 ? true : false}>
                {num + 1}
              </option>
            );
          })}
        {quantity < 1 && (
          <option value={0} selected>
            0
          </option>
        )}
      </select> */}
      <div style={{display: "flex", alignItems: 'center', justifyContent: 'flex-start', gap: 10, flexWrap: "wrap"}}>
        <button disabled={disabled ? disabled : quantit<=1 ? true: false} onClick={()=> {handelDicriment(); }} style={{borderRadius: "6px", textAlign: 'center', fontSize: '20px', backgroundColor: '#7fad39', color: "#fff", paddingInline: "20px" , cursor: 'pointer'}}>-</button>
        <input onChange={handleInputChange} type="number" value={quantit<10 ? `0${prodQt}`: prodQt}  placeholder="01" style={{fontSize: "18px", paddingInline: "5px", borderWidth: "1px", borderStyle: 'solid', borderColor: "#495057", borderRadius: "6px", maxWidth: "20%", textAlign: "center"}}/>
        <button disabled={disabled ? disabled :  quantit === quantity ? true: false} onClick={()=> {handelIncriment();}} style={{borderRadius: "6px", textAlign: 'center', fontSize: '20px', backgroundColor: '#7fad39', color: "#fff", paddingInline: "20px" , cursor: 'pointer'}}>+</button>
        <span style={{fontSize: "20px", fontWeight: '600', textTransform: 'capitalize', color: '#000000', display: "flex", alignItems: 'center', justifyContent: 'center',gap: "5px"}}>Stock:  <span style={{fontSize: "16px", fontWeight: '400', textTransform: 'capitalize', color: '#6f6f6f'}}>{quantity}</span></span>
      </div>

    </div>
    
  );
}
