import React from "react";
import Table from "./Table";
import i18n from "../i18n/i18n";

export default function PackProdElements({ pack, lng, children }) {
  return (
    <React.Fragment>
      <Table
        children={children}
        thArr={[
          i18n.t("products.label", { lng }),
          i18n.t("price.label", { lng }),
          i18n.t("quantity.label", { lng }),
          i18n.t("total.label", { lng }),
        ]}
      />
      
    </React.Fragment>
  );
}
