import React, { useEffect, Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPass from "./components/auth/ForgotPass";
import ShoppingCart from "./components/shoppingCart/ShoppingCart";
import Checkout from "./components/checkout/Checkout";
import Renting from "./components/renting/Renting";
import ProductPage from "./components/renting/ProductPage";
import Bill from "./components/bill/Bill";
import Ecommerce from "./components/e-commerce/Ecommerce";
import Contracts from "./components/contracts/Contracts";
import Contact from "./components/contact/Contact";
import Search from "./components/search/Search";
import PasswordReset from "./components/auth/PasswordReset";
import FouroFour from "./components/404/FouroFour";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "mobx-react";
import Store from "./store/Store";
import RedirectRoute from "./RedirectRoute";
import Spinner from "./components/Spinner";
import { FaArrowUp } from "react-icons/fa";




function App() {
  const [loading, cLoading] = useState(true);
  const navIcon = Store.getNavIcon;
  useEffect(() => {

    const userData = localStorage.getItem("userData");

    //check if user has navIcon avlbl
    const navIcon = localStorage.getItem("navIcon")
    if (navIcon !== null) {
      Store.changeNavIcon(JSON.parse(navIcon));
    }
    //check if user has a lang selected
    const lang = localStorage.getItem("lang")
    if (lang !== null) {
      Store.changeLang(JSON.parse(lang));
    }
    //check if user is logged in(userdata in localstorage)
    if (Store.getUser.email.length < 1 && userData != null) {
      //check if token is still valid here

      const parsedData = JSON.parse(userData);
      Store.loginUser(parsedData);
      cLoading(false)
    } else {
      //check if user is in login or register route, else redirect to login

      if (window.location.pathname !== "/login" && !window.location.pathname.includes("/password/reset")) {
        window.location.pathname = "/login";
      }

    }
    //user in login /pass reset stop main loader
    if (window.location.pathname === "/login" || window.location.pathname.includes("/password/reset")) {
      cLoading(false);

    }
  }, []);
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const showScrollTopBtn = () => {
      window.scrollTo(0,0); 
  }
  console.log('Scroll Ecommerce', scrollY)

  return (
    <Fragment>
      <Helmet>
        {<link rel="icon" href={navIcon} />}
      </Helmet>
      {loading ?
        <div style={{ height: "45rem" }}><Spinner /></div> :
        <Provider store={Store}>
          <Router>
            <Switch>
              <Route exact path="/">
                <RedirectRoute />
              </Route>
              {/*Auth*/}
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/forgot-password">
                <ForgotPass />
              </Route>

              <Route path="/password/reset">
                <PasswordReset />
              </Route>
              {/******/}
              <Route path="/shopping-cart">
                <ShoppingCart />
              </Route>
              <Route path="/checkout">
                <Checkout />
              </Route>
              <Route path="/renting">
                <Renting />
              </Route>
              <Route path="/bill">
                <Bill />
              </Route>
              <Route path="/e-commerce">
                <Ecommerce />
              </Route>
              <Route path="/contracts">
                <Contracts />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/search">
                <Search />
              </Route>
              <Route
                path="/product-page"
                render={(props) => <ProductPage {...props} />}
              />
              <Route>
                <FouroFour />
              </Route>
            </Switch>
          </Router>
        </Provider>
      }
      {scrollY > 200 && (
        <button 
        onClick={()=> showScrollTopBtn()} 
        className="scrollToTop" 
        style={{
          position: "fixed", 
          right: "20px", 
          bottom: "10px",
          zIndex: 9999, 
          borderRadius: 50, 
          padding: "10px", 
          // background:"rgb(200,214,229)",
          background:"linear-gradient(180deg, rgba(200,214,229,1) 0%, rgba(138,233,179,1) 100%)"

        }}>
          <FaArrowUp style={{color: "#fff", fontSize: "30px", }} />
        </button>
      )
      }
    </Fragment>
  );
}

export default App;
