import React from "react";
import Table from "../Table";
import style from "../../utils/style";
import i18n from "../../i18n/i18n";
function ContractSection({ children, lng }) {
  return (
    <div className="container" style={style.contractSectionContainer}>
      <Table
        children={children}
        thArr={[
          "Id",
          i18n.t("name.label", { lng }),
          i18n.t("total.label", { lng }),

          i18n.t("start.label", { lng }),
          i18n.t("end.label", { lng }),
          i18n.t("status.label", { lng }),
        ]}
      />
    </div>
  );
}
export default ContractSection;
