import React from "react";
import { withRouter } from "react-router-dom";
import { redirectTo } from "../../utils/function";
import { serverUrl } from "../../utils/function";
import noImage from '../../Assets/noImg.png'
import CurHolder from "../CurHolder";
function Product({
  prodId,
  prodInfoId,
  name,
  price,
  desc,
  img,
  type,
  history,
  curr,
  quantity,
  userData,
  cadance,
  tax, 
  images,
  productsPath
}) {
  const redirectProdPage = (hehe = "hehe") => {

    return history.push({
      pathname: "/product-page",
      state: {
        prodId,
        prodInfoId,
        name,
        price,
        desc,
        img,
        type,
        curr,
        quantity,
        cadance,
        tax, 
        images,
        productsPath
      }
    })
  }
  console.log('the image of the producti is : ', serverUrl() === img); 
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mix oranges fresh-meat product_card">
      <div className="featured__item ">
        <div
          className="featured__item__pic set-bg"
          style={{
            backgroundImage: `url(${serverUrl() !== img ? img: noImage})` ,
            backgroundSize: "contain",
            cursor: "pointer",
          }}
          onClick={() =>
            redirectProdPage()
          }
        >
        </div>
        <div className="featured__item__text">
          <h6>
            <a
              style={{ cursor: "pointer" }}
              onClick={() =>
                redirectProdPage()
              }
            >
              {name}
            </a>
          </h6>
          <h5>
            <CurHolder amount={price} curr={curr} />
          </h5>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Product);
