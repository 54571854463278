export function serverUrl() {
  //returns server url
  return process.env.REACT_APP_SERVER_URL;
}
function companyApp() {
  return process.env.REACT_APP_COMPANY_APP;
}
export function post(method = "POST", url, data) {
  //the db we want to target
  data.company_app = companyApp();
  return new Promise((resolve, reject) => {
    fetch(
      url +
      new URLSearchParams({
        company_app: companyApp(),
        app_code: companyApp(),
      }),
      {
        method,
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((json) => resolve(json))
      .catch((e) => reject(e));
  });
}
export function get(method = "GET", url, token, page = 1, per_page = 50) {
  return new Promise((resolve, reject) => {
    fetch(
      url +
      new URLSearchParams({
        company_app: companyApp(),
        app_code: companyApp(),
        page,
        per_page
      }),
      {
        method,
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => resolve(json))
      .catch((e) => reject(e));
  });
}

export function postAuth(method = "POST", url, token, data) {
  return new Promise((resolve, reject) => {
    fetch(
      url +
      new URLSearchParams({
        company_app: companyApp(),
      }),
      {
        method,
        mode: "cors",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((json) => resolve(json))
      .catch((e) => reject(e));
  });
}

export function reformatDate(dateString) {
  //takes date string makes data obj and then fromats it to dd-mm-yyyyy using formatDate
  const newDstring = dateString.split(" ")[0];

  return formatDate(new Date(newDstring), false);
}
export function formatDate(date, yearFirst = true) {
  //turn date into format daypicker can use yyyy-mm-dd
  if (date == null) return;
  var d = date;
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return yearFirst ? [year, month, day].join("-") : [day, month, year].join("-");
}

export function calculateBooking(duration, price) {
  const total = (duration * price) / 30;
  if (isNaN(total)) return 0;
  return total.toFixed(2);
}

export function procCur(currObj) {
  //proccess currobj and decides what to set the curr to
  if (currObj == undefined) return;
  return currObj.symbol == null ? currObj.iso : currObj.symbol;
}
export function redirectTo(his, page, data) {
  return his.push({
    pathname: "/" + page,
    state: {
      ...data,
    },
  });
}

export function PercentToValue(perc, value) {

  return ((perc * value) / 100).toFixed(2);
}
export function discountCalc(perc, value) {
  const dis = (perc * value) / 100;
  return dis.toFixed(2);
}

export function accessRedirect(history, accessObj) {

  //redirect based on access obj passed
  const { ecom, renting } = accessObj;


  //cast 1 and 0 to true false
  if (ecom === 1 && renting === 1) return history.push({
    pathname: "/e-commerce",
  });
  if (ecom === 1) return history.push({
    pathname: "/e-commerce",
  });
  if (renting === 1) return history.push({
    pathname: "/renting",
  });
  //we don't have any perms, show the 404 page
  return history.push({
    pathname: "/404",
  });

}
export const setNavIcon = (cNavIcon, rentingCon, ecomCon) => {
  if (rentingCon && ecomCon) cNavIcon(rentingCon);
  if (rentingCon && !ecomCon) cNavIcon(rentingCon);
  if (ecomCon && !rentingCon) cNavIcon(ecomCon);
}
