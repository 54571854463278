import React from "react";
import Popup from "reactjs-popup";
import styles from "../../utils/style"

export default function BillAlert({ showAlert, msg, color, changeAlertCb }) {
    return (
        <Popup
            modal
            closeOnDocumentClick
            lockScroll
            open={showAlert}
            position="top center"
            onClose={() => {
                //user closed the thing update state to match
                changeAlertCb();
            }}
            contentStyle={{ backgroundColor: "transparent", border: "" }}
        >
            <div style={styles.alertDiv}
                className={color == "green" ? "alert alert-success" : "alert alert-danger"}
                role="alert">
                {msg}
            </div>
        </Popup>
    );
}
