import React from "react";
import { Helmet } from "react-helmet";

export default function Filter({ cats, filterCb, activeCatId, themeColor }) {
  console.log("activeCatId", activeCatId)
  return (
    <div className="row" style={{ marginTop: "3rem" }}>
      <div className="col-lg-12">
        <div className="featured__controls">
          <ul>
            <li
              className={activeCatId == -1 ? "active" : ""}
              id={-1}
              onClick={(e) => filterCb(e.target.id)}
            >
              All
            </li>
            {cats.map((cat, index) => {
              return (
                <li
                  className={activeCatId == cat.id ? "active" : ""}
                  id={cat.id}
                  onClick={(e) => filterCb(e.target.id)}
                  key={index}
                >
                  {cat.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Helmet>
        <style>{`.featured__controls ul li:after {
                  position: absolute;
                  left: 0;
                  bottom: -2px;
                  width: 100%;
                  height: 2px;
                  background:${themeColor};
                  content: "";
                  opacity: 0;
                }
                                          
                  `}</style>
      </Helmet>
    </div>
  );
}
