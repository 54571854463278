import React, { Fragment } from 'react'
import { Helmet } from "react-helmet";

function Wrapper({ themeColor, children }) {
    return (
        <div id="app-login">
            {children}
            <Helmet>
                <style>{`#auth-btn {
	font-size: .9rem;
	background-color:${themeColor};
		border-color:${themeColor};
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
}
.ogani-input:hover, .ogani-input:focus {
	box-shadow: 0 0 0 0.2rem ${themeColor}66 ;
	border-color:${themeColor};
}
#app-login {
	text-transform: uppercase;
	font-weight: bold;
}
}
                          
  `}</style>
            </Helmet>
        </div>
    )
}

export default Wrapper
