import React from "react";

export default function Table({ children, thArr }) {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="shoping__cart__table">
          <table>
            <thead>
              <tr>
                {thArr.map((item, index) => {
                  return (
                    <th
                      className={index === 0 ? "shoping__product" : ""}
                      key={index}
                    >
                      {item}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
