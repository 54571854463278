import React from "react";
import CurrHolder from "./CurHolder";
import { serverUrl } from "../utils/function";
export default function PackProdItem({
  prodId,
  name,
  unitprice,
  description,
  curr,
  img,
  prodQt,
  quantity,
  price,
  total,

}) {
  return (
    <tr>
      <td className="shoping__cart__item">
        <img
          src={serverUrl() + img}
          alt=""
          style={{ maxHeight: "3rem", marginBottom: "1rem" }}
        />
        <div style={{ display: "inline-block" }}>
          <h5>{name}</h5>
          <p>{description}</p>
        </div>
      </td>
      <td className="shoping__cart__price" style={{ fontWeight: "normal" }}>
        <CurrHolder amount={price} curr={curr} />
      </td>
      <td className="shoping__cart__quantity">{quantity}</td>
      <td className="shoping__cart__total" style={{ fontWeight: "normal" }}>
        <CurrHolder amount={total} curr={curr} />
      </td>
    </tr>
  );
}
