import React, { useState, useEffect, Fragment } from "react";
import AuthHeader from "./AuthHeader";
import { serverUrl, post, accessRedirect, get, setNavIcon } from "../../utils/function";
import { inject, observer } from "mobx-react";
import i18n from "../../i18n/i18n"
import { withRouter } from "react-router-dom";
import Wrapper from "./Wrapper";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

function Register({ store, history }) {
  const [lastName, changeLastName] = useState("");
  const [firstName, cFirstName] = useState("");
  const [email, changeEmail] = useState("");
  const [phone, changePhone] = useState("");
  const [password, changePassword] = useState("");
  const [conPassword, changeConPassword] = useState("");
  const [themeColor, cThemeColor] = useState("")
  const [logo, cLogo] = useState("");
  const [appName, cAppName] = useState("");
  const [loading, cLoading] = useState(true);
  const [navIcon, cNavIcon] = useState("");

  const lng = store.getLang;
  useEffect(() => {
    getTheme()
  }, [])

  useEffect(() => {
    if (navIcon.length > 0) store.changeNavIcon(navIcon);
  }, [navIcon])
  
  const getTheme = async () => {
    cLoading(true);

    const url = serverUrl() + "api/customer/get_theme_color?";

    try {
      const response = await get("GET", url);
      const { theme_color, app_img, app_name, renting_icon, ecom_icon } = response.data;
      console.log("response.data", response.data)
      cThemeColor(theme_color);
      cLogo(app_img);
      cAppName(app_name)
      setNavIcon(cNavIcon, renting_icon, ecom_icon)

      cLoading(false);

    } catch (e) {
      console.log(e);
      const userData = store.getUser;
      cLoading(false);

      cThemeColor(userData.defaultThemeColor);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "phone") changePhone(value);

    if (name === "lastName") changeLastName(value);

    if (name === "firstName") cFirstName(value);

    if (name === "email") changeEmail(value);

    if (name === "password") changePassword(value);

    if (name === "password_confirmation") changeConPassword(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== conPassword) return alert("make sure the passwords match");
    const url = serverUrl() + "api/customer/register?";
    const data = {
      name: lastName,
      fname: firstName,
      phone: phone,
      username: email,
      password: password,
      // company_app:  process.env.REACT_APP_COMPANY_APP,
      // app_code:  process.env.REACT_APP_COMPANY_CODE,
      type_customer: "client",
      gender: 0,
    };
    try {
      const responseData = await post("POST", url, data);
      //login success redirect
      if (responseData.access_token !== undefined) {
        const data = {
          accessToken: responseData.access_token,
          email: email,
          actorId: responseData.actorId,
          companyApp: responseData.company_app,
          appIcon: responseData.app_icon,
          currency: responseData.currency,
          access: responseData.access,
          themeColor: responseData.theme_color,
          publishableStripeKey: responseData.stripe_public
        };
        //save user data to store
        store.loginUser(data);
        //redirect to appropriate page
        console.log('response after register', responseData)
        accessRedirect(history, responseData.access);
      }
      //else alert fail
      else {
        alert(responseData.details.username);
      }
      console.log(responseData);
    } catch (e) {
      console.log(e);
      alert("couldn't reach the server");
    }
  };
  return (
    <Wrapper themeColor={themeColor}>
      {loading ? <div style={{ height: "45rem" }}><Spinner /></div> :
        <Fragment>
          <AuthHeader lng={lng} logo={logo} appName={appName} />
          <main class="py-4">
            <div class="container">
              <div class="row justify-content-center">

                <div class="col-md-8">
                  <div class="card">
                    <div class="card-header">{i18n.t("register.label", { lng })}
                    </div>

                    <div class="card-body">
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <div class="form-group row">
                          <label
                            htmlFor="firstName"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("firstName.label", { lng })}
                          </label>

                          <div class="col-md-6">
                            <input
                              id="firstName"
                              type="firstName"
                              class="ogani-input form-control "
                              name="firstName"
                              value={firstName}
                              onChange={handleChange}
                              required
                            />

                            <span class="invalid-feedback" role="alert">
                              <strong>msg </strong>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            htmlFor="lastName"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("lastName.label", { lng })}
                          </label>

                          <div class="col-md-6">
                            <input
                              id="lastName"
                              type="lastName"
                              class="ogani-input form-control "
                              name="lastName"
                              value={lastName}
                              onChange={handleChange}
                              required
                            />

                            <span class="invalid-feedback" role="alert">
                              <strong>msg </strong>
                            </span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label
                            htmlFor="email"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            Email
                      </label>

                          <div class="col-md-6">
                            <input
                              id="email"
                              type="email"
                              class="ogani-input form-control "
                              value={email}
                              name="email"
                              onChange={handleChange}
                              required
                            />

                            <span class="invalid-feedback" role="alert">
                              <strong>msg </strong>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            htmlFor="phone"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("phoneNumber.label", { lng })}

                          </label>

                          <div class="col-md-6">
                            <input
                              id="phone"
                              type="phone"
                              class="ogani-input form-control "
                              name="phone"
                              value={phone}
                              onChange={handleChange}
                              required
                            />

                            <span class="invalid-feedback" role="alert">
                              <strong>msg </strong>
                            </span>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label
                            htmlFor="password"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("password.label", { lng })}
                          </label>

                          <div class="col-md-6">
                            <input
                              id="password"
                              type="password"
                              class="ogani-input form-control"
                              name="password"
                              required
                              autoComplete="new-password"
                              value={password}
                              onChange={handleChange}
                              minlength="6"
                            />

                            <span class="invalid-feedback" role="alert">
                              <strong> message </strong>
                            </span>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label
                            htmlFor="password-confirm"
                            class="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("confirmPassword.label", { lng })}
                          </label>

                          <div class="col-md-6">
                            <input
                              id="password-confirm"
                              type="password"
                              class="ogani-input form-control"
                              name="password_confirmation"
                              required
                              autoComplete="new-password"
                              value={conPassword}
                              onChange={handleChange}
                              minlength="6"
                            />
                          </div>
                        </div>

                        <div class="form-group row mb-0">
                          <div class="col-md-6 offset-md-4">
                            <button
                              id="auth-btn"
                              type="submit"
                              class="btn btn-primary"
                            >
                              {i18n.t("register.label", { lng })}

                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Helmet>
              {<link rel="icon" href={navIcon} />}
            </Helmet>
          </main>
        </Fragment>}
    </Wrapper>);
}

export default withRouter(inject("store")(observer(Register)));
