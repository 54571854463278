import React from "react";
import style from "../../utils/style";
import { reformatDate, procCur } from "../../utils/function"
import CurHolder from "../CurHolder";
export default function ContractItem({ id, dateStart, dateEnd, stateNow, name, totalPrice, curr }) {
  const { color, text, bg } = stateNow;
  reformatDate(dateStart)
  return (
    <tr>
      <td className="shoping__cart__item" style={style.contractItems.contractId}>{id}</td>
      <td
        className="shoping__cart__quantity"
        style={style.contractItems.contractStart}
      >
        {name}{" "}
      </td>
      <td
        className="shoping__cart__quantity"
        style={style.contractItems.contractStart}
      >
        <CurHolder amount={totalPrice} curr={curr} />

      </td>
      <td
        className="shoping__cart__quantity"
        style={style.contractItems.contractStart}
      >
        {reformatDate(dateStart)}{" "}
      </td>
      <td
        className="shoping__cart__quantity"
        style={style.contractItems.contractEnd}
      >
        {reformatDate(dateEnd)}
      </td>
      <td
        className="shoping__cart__quantity"
        style={style.contractItems.contractEnd}
      >
        <span className="badge" style={{ color, background: bg }}>
          {" "}
          {text}{" "}
        </span>
      </td>

      <td className="shoping__cart__item__close">
        <span className="icon_close"></span>
      </td>
    </tr>
  );
}
