import React, { useEffect, useState, Fragment } from "react";
import { serverUrl, post, accessRedirect, get, setNavIcon } from "../../utils/function";
import AuthHeader from "./AuthHeader";
import { Redirect, Link, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import i18n from "../../i18n/i18n"
import Wrapper from "./Wrapper";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

function Login({ store, history }) {
  const [email, changeEmail] = useState("");
  const [password, changePassword] = useState("");
  const [themeColor, cThemeColor] = useState("");
  const [logo, cLogo] = useState("");
  const [appName, cAppName] = useState("");
  const [defaultLang, cDefaultLang] = useState("");
  const [langPerms, cLangPerms] = useState(0);
  const [loading, cLoading] = useState(true);
  const [navIcon, cNavIcon] = useState("");
  const lng = store.getLang;
  useEffect(() => {
    cLoading(true);
    getTheme()
  }
    , [])


  useEffect(() => {
    if (navIcon.length > 0) store.changeNavIcon(navIcon);
  }, [navIcon])
  const getTheme = async () => {
    //doesn't only fetch the theme
    const url = serverUrl() + "api/customer/get_theme_color?";

    try {
      const response = await get("GET", url);
      //gracefully fail if requests returns something that's not the data we want

      const { theme_color, app_img, app_name, default_lang, access, renting_icon, ecom_icon } = response.data;
      const multiLang = access.multi_lang;
      cThemeColor(theme_color);
      cLogo(app_img);
      cAppName(app_name)
      cDefaultLang(default_lang)
      cLangPerms(multiLang)
      setNavIcon(cNavIcon, renting_icon, ecom_icon)
      cLoading(false);
    } catch (e) {
      console.log(e);
      const userData = store.getUser;
      cLoading(false);

      cThemeColor(userData.defaultThemeColor);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    e.target.name === "password" ? changePassword(value) : changeEmail(value);
    return;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = serverUrl() + "api/customer/login?";
    const data = {
      username: email,
      password: password,
      //  company_app: "woo",
      type: "client",
    };
    try {
      const responseData = await post("POST", url, data);
      console.log("responseData", responseData)
      //login success redirect
      if (responseData.access_token != undefined) {
        const data = {
          accessToken: responseData.access_token,
          email: email,
          actorId: responseData.actorId,
          companyApp: responseData.company_app,
          appIcon: responseData.app_icon,
          currency: responseData.currency,
          access: responseData.access,
          themeColor: responseData.theme_color,
          publishableStripeKey: responseData.stripe_public
        };

        //save user data to store
        store.loginUser(data);
        //redirect to appropriate page
        accessRedirect(history, responseData.access);

      }
      //else alert fail
      else alert("couldn't login, check your creds");
      console.log(responseData);
    } catch (e) {
      console.log(e);
      alert("couldn't reach the server");
    }
  };
  return (
    <Wrapper themeColor={themeColor}>
      {loading ?
        <div style={{ height: "45rem" }}><Spinner /></div> :
        <Fragment>
          <AuthHeader lng={lng} logo={logo} appName={appName} defaultLang={defaultLang} langPerms={langPerms} />
          <main className="py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header">{i18n.t("login.label", { lng })}</div>

                    <div className="card-body">
                      <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-group row">
                          <label
                            htmlFor="email"
                            className="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("phoneNumber.label", { lng })}
                          </label>

                          <div className="col-md-6">
                            <input
                              id="email"
                              type="text"
                              className="ogani-input form-control "
                              name="email"
                              required
                              autoFocus
                              value={email}
                              onChange={handleChange}
                            />

                            <span className="invalid-feedback" role="alert">
                              <strong>msg</strong>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="password"
                            className="col-md-4 col-form-label text-md-right"
                          >
                            {i18n.t("password.label", { lng })}

                          </label>

                          <div className="col-md-6">
                            <input
                              id="password"
                              type="password"
                              className="ogani-input form-control "
                              name="password"
                              required
                              autoComplete="current-password"
                              value={password}
                              onChange={handleChange}
                            />

                            <span className="invalid-feedback" role="alert">
                              <strong>msg</strong>
                            </span>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-md-6 offset-md-4">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="remember"
                                id="remember"

                              />

                              {/*<label className="form-check-label" for="remember">
                            Remember Me
  </label>*/}
                            </div>
                          </div>
                        </div>

                        <div className="form-group row mb-0">
                          <div className="col-md-8 offset-md-4">
                            <button id="auth-btn" className="btn btn-primary" style={{ background: themeColor }}
                            >
                              {i18n.t("login.label", { lng })}

                            </button>
                            <Link to={"forgot-password"}
                              className="btn btn-link"
                            >
                              {i18n.t("forgotPassword.label", { lng })}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </main>
          <Helmet>
            {<link rel="icon" href={navIcon} />}
          </Helmet>
        </Fragment>}
    </Wrapper>)
}

export default withRouter(inject("store")(observer(Login)));
