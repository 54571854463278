import React, { useState } from "react";
import AlertModal from "../AlertModal";
import i18n from "../../i18n/i18n";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";
import { withRouter } from "react-router-dom";
import QunInput from "../QunInput";
function EcomProdSection({
  desc,
  prodId,
  prodInfoId,
  name,
  img,
  type,
  curr,
  price,
  lng,
  store,
  history,
  quantity,
  themeColor
}) {
  const [showAlert, changeShowAlert] = useState(false);
  const [prodQt, changeProdQt] = useState(1);
  const [inCart, changeInCart] = useState(false);

  React.useEffect(
    () =>
      autorun(() => {
        //get data from store and set it to state
        const currProd = store.getCartProds.filter(
          (prod) => prod.prodId === prodId
        );
        const newQt = currProd[0].prodQt === undefined ? 1 : currProd[0].prodQt;
        //if in cart update the state here and change qtt
        if (currProd[0].name) changeInCart(true);
        changeProdQt(newQt);
      }),
    [] // note empty dependencies
  );
  //get prod if there's one
  const changeAlertCb = () => {
    changeShowAlert(false);
    return;
  };
  const addToCart = (data) => {
    store.addCartProds(data);
    return;
  };
  const qtCb = (value) => {
    changeProdQt(value);
  };
  return (
    <React.Fragment>
      <AlertModal
        changeAlertCb={changeAlertCb}
        showAlert={showAlert}
        lng={lng}
      />
      <div style={{display: "flex", alignItems: 'flex-start', justifyContent: 'space-between', flexWrap: 'wrap', gap: 10, flexDirection: 'column'}}>
        <div class="product__details__quantity">
          <div class="input-group">
            <QunInput
              quantity={quantity}
              prodQt={prodQt}
              qtCb={qtCb}
              //if product in cart disable editing qtt here or qtt inferior to one
              disabled={inCart || quantity < 1 ? true : false}
            />
          </div>
        </div>
        <span
          style={{ color: "#fff", cursor: "pointer", background: themeColor, width: "100%", textAlign: "center" }}
          onClick={() => {
            //product in cart redirect to cart
            if (inCart) {
              history.push({
                pathname: "/shopping-cart",
              });
              return;
            }
            //add to cart
            if (price !== 0 && quantity !== 0) {
              addToCart({
                desc,
                prodId,
                prodInfoId,
                name,
                img,
                type,
                curr,
                price,
                prodQt,
                quantity,
              });
              return;
            }
            //price or qtt is null alert user
            changeShowAlert(true);
          }}
          class="primary-btn"
        >
          {!inCart
            ? i18n.t("addToCart.label", { lng })
            : i18n.t("viewInCart.label", { lng })}
        </span>
      </div>
      
    </React.Fragment>
  );
}

export default withRouter(inject("store")(observer(EcomProdSection)));
