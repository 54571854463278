import React from "react";
import PhoneEl from "./PhoneEl";
import AdrsEl from "./AdrsEl";
import EmailEl from "./EmailEl";
import style from "../../utils/style";
import i18n from "../../i18n/i18n";
function UserInfoSection({ phoneArr, adrsArr, mailArr, lng, themeColor }) {
  return (
    <section className="contact spad">
      <div className="container">
        <div className="row" style={style.contactElContainer}>
          <PhoneEl phoneArr={phoneArr} themeColor={themeColor} title={i18n.t("phone.label", { lng })} />
          <AdrsEl adrsArr={adrsArr} themeColor={themeColor} title={i18n.t("address.label", { lng })} />
          <EmailEl mailArr={mailArr} themeColor={themeColor} title={i18n.t("email.label", { lng })} />
        </div>
      </div>
    </section>
  );
}

export default UserInfoSection;
