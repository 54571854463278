import React, { Fragment } from "react";

function EmailEl({ mailArr, title, themeColor }) {
  return (
    <Fragment>
      {mailArr.length > 0 ? (
        <div className="col-lg-3 col-md-3 col-sm-6 text-center">
          <div className="contact__widget">
            <span className="icon_mail_alt " style={{ color: themeColor }}></span>
            <h4>{title}</h4>
            {mailArr.map((ph, index) => {
              return <p>{ph.info}</p>;
            })}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default EmailEl;
