import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "../../i18n/i18n";
import { observer, inject } from "mobx-react";
import { serverUrl, postAuth, formatDate } from "../../utils/function";
import styles from "../../utils/style";
import CurrHolder from "../CurHolder";
import style from "../../utils/style";
import Spinner from "../Spinner";
import BillAlert from "./BillAlert";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../../2-Card-Detailed.css";


const CardField = ({ onChange, CARD_OPTIONS }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
    <div className="FormRow">
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  );

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button type="button" className="ResetButton" onClick={onClick}>
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
      />
    </svg>
  </button>
);

const CheckoutForm = ({ client_secret, stripeCB, submitForm, cSubmitForm, formData, themeColor }) => {
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: themeColor,
        fontWeight: 500,
        fontFamily: "Cairo, sans-serif",

        fontSize: "16px",
        fontSmoothing: "antialiased",

      },
      invalid: {
        iconColor: "#eb1c26",
        color: "#eb1c26",
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });
  useEffect(() => {

    if (submitForm) {
      cSubmitForm(false);
      handleSubmit(error)
    }
  }, [submitForm])

  const handleSubmit = async (error) => {

    console.log("error", error)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      stripeCB({ error: error })

      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: { name: formData.firstName + " " + formData.lastName },

      }
    });


    console.log("payload", payload);
    stripeCB(payload)
    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: "",
      phone: "",
      name: "",
    });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {paymentMethod.id}
      </div>
      <ResetButton onClick={reset} />
    </div>
  ) : (
      <form className="Form" onSubmit={handleSubmit}>
        {/*<fieldset className="FormGroup">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
          <Field
            label="Phone"
            id="phone"
            type="tel"
            placeholder="(941) 555-0123"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, phone: e.target.value });
            }}
          />}
          </fieldset>*/}
        <fieldset className="FormGroup">
          <label for="formGroupExampleInput">Numéro de carte <RedSp txt={"*"} /></label>

          <CardField
            CARD_OPTIONS={CARD_OPTIONS}
            onChange={(e) => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
          />
        </fieldset>
        {/*<SubmitButton processing={processing} error={error} disabled={!stripe}>
          Pay $25
      </SubmitButton>*/}
      </form>
    );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let publishableStripeKey = ""
const userData = localStorage.getItem("userData");
if (userData !== null) {
  const parsedData = JSON.parse(userData);
  publishableStripeKey = parsedData.publishableStripeKey;
}
const stripePromise = loadStripe(
  publishableStripeKey
);

function RedSp({ txt }) {
  return <span style={{ color: "red" }}>{txt} </span>;
}
function BillContent({ history, location, store }) {
  const [loading, cLoading] = useState(false);
  const [alertDis, cAlertDis] = useState(false);
  const [alertMsg, cAlertMsg] = useState("")
  const [alertColor, cAlertColor] = useState("")
  const [submitForm, cSubmitForm] = useState(false);
  const [formData, cFormData] = useState({ firstName: "", lastName: "", country: "", city: "", addrs: "" })
  if (location.state == undefined) history.push("/renting");
  const locState = location.state == undefined ? {} : location.state;

  const {
    totalPrice,
    price,
    name,
    img,
    curr,
    dateStart,
    dateEnd,
    prodInfoId,
    prodId,
    packId,
    packInfoArray,
    cadance,
    tax,
    client_secret
  } = locState;
  const lng = store.getLang;
  const userData = store.getUser;
  const themeColor = userData.themeColor ? userData.themeColor : userData.defaultThemeColor
  const taxes = packInfoArray ? packInfoArray : tax
  const stopAndRedirect = (path) => {
    setTimeout(() => {
      //this prevents the user from going back
      history.replace("bill", undefined)
      history.push(path);

    }, 800)
  };
  const handleFeedback = (response) => {
    //handle popup alert and it's msg and the loading state
    cLoading(false);
    if (!response) {
      cAlertDis(true)
      cAlertMsg("Failed to connect to server")
      cAlertColor("red")
      return;
    }
    if (response.state === 200) {
      cAlertDis(true)
      cAlertColor("green")
      cAlertMsg(response.msg)
      //redirect to contracts if success
      stopAndRedirect("contracts");

    } else {
      cAlertDis(true)
      cAlertColor("red")
      cAlertMsg(response.msg ? response.msg : "Server returned an error")
    }
    return;
  }
  const finlizeBooking = () => {
    if (packId == undefined)
      return finlizeProductBooking(
        prodId,
        prodInfoId,
        dateStart,
        dateEnd,
        userData
      );
    finilizePackBooking(packId, dateStart, dateEnd, userData);
  };
  const finilizePackBooking = async (packId, dateStart, dateEnd, userData) => {
    const url = serverUrl() + "api/customer/renting/validate_book?";
    const token = userData.acessToken;
    try {
      const response = await postAuth("POST", url, token, {
        pack_id: packId,
        dt_start: dateStart,
        dt_end: dateEnd,
      });
      handleFeedback(response.data)
    } catch (e) {
      console.log(e);
      handleFeedback(undefined);
    }
    return;
  };
  const finlizeProductBooking = async (
    prodId,
    prodInfoId,
    dateStart,
    dateEnd,
    userData
  ) => {
    const url = serverUrl() + "api/customer/renting/validate_book?";
    const token = userData.acessToken;

    try {
      const response = await postAuth("POST", url, token, {
        prod_id: prodId,
        prod_info_id: prodInfoId,
        dt_start: dateStart,
        dt_end: dateEnd,
      });
      handleFeedback(response.data)
    } catch (e) {
      handleFeedback(undefined)
    }
    return;
  };
  const stripeCB = (stripeData) => {
    cLoading(false)

    if (!stripeData.error && stripeData.paymentIntent.status === "succeeded") {
      //stripe checks out proceed with finlizing book
      finlizeBooking(prodId, prodInfoId);
    } else {
      //there was an error with stripe or something else?
      cAlertDis(true)
      cAlertMsg(stripeData.error.message ? stripeData.error.message : "There was a problem")
      cAlertColor("red")
    }


    return;
  }
  console.log("formData", formData)
  return (
    <div className="container" style={{ marginTop: "3rem" }}>
      <BillAlert changeAlertCb={() => cAlertDis(false)} color={alertColor} showAlert={alertDis} msg={alertMsg} />
      <div className="row">
        <div className="col-sm">
          {" "}
          <div className="card shadow-sm " style={styles.card} >
            <div className="card-header" style={styles.cardHeader}>
              <h5 className="h5 text-center" style={styles.CardHeaderTxt}>
                {i18n.t("billDet.label", { lng })}
              </h5>
            </div>
            <div style={styles.formGpPadding}>
              <div className="form-row">
                <div className="form-group col">
                  <label>Prénom <RedSp txt={"*"} /></label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Prénom"
                    value={formData.firstName}
                    onChange={(e) => {
                      cFormData({ ...formData, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group col">
                  <label>Nom <RedSp txt={"*"} /></label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nom"
                    value={formData.lastName}
                    onChange={(e) => {
                      cFormData({ ...formData, lastName: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group col">
                  <label>Entreprise</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Entreprise"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Pays <RedSp txt={"*"} /></label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pays"
                    value={formData.country}
                    onChange={(e) => {
                      cFormData({ ...formData, country: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group col">
                  <label>Ville <RedSp txt={"*"} /> </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ville"
                    value={formData.city}
                    onChange={(e) => {
                      cFormData({ ...formData, city: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group col-sm-4">
                  <label>Etat/Province/Region</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Etat/Province/Region"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Adresse <RedSp txt={"*"} /></label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={formData.addrs}
                    onChange={(e) => {
                      cFormData({ ...formData, addrs: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group col-sm-4">
                  <label>Code Postal/ZIP</label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Code Postal/ZIP"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          {/* product cart */}
          <div className="card shadow-sm " style={styles.card}>
            <img
              className="card-img-top"
              alt="Card image cap"
              src={img}
              style={{
                height: "70%",
                width: "100%",
                border: "1px solid grey",
                backgroundSize: "contain",
              }}
            />
            <div style={{ padding: ".5rem" }} className="card-body">
              <h5 className="card-title h5">{name} </h5>
              <h5 className="h5 text-bold">
                <CurrHolder amount={price} curr={curr} /> {cadance}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={styles.rowPadding}>
        <div className="col-sm">
          {/* cart card */}
          <div className="card shadow-sm " style={styles.card}>

            <div className="card-header" style={styles.cardHeader}>
              <h5 className="h5" style={styles.CardHeaderTxt}>              <img src="img/visa_mastercard.png" style={{ marginRight: "1rem" }}></img>
                {i18n.t("creditCart.label", { lng })}</h5>
            </div>
            <div style={styles.formGpPadding}>
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <CheckoutForm
                  client_secret={client_secret}
                  stripeCB={stripeCB}
                  submitForm={submitForm}
                  cSubmitForm={cSubmitForm}
                  formData={formData}
                  themeColor={themeColor} />
              </Elements>
            </div>
          </div>
        </div>
        <div className="col-sm">
          {" "}
          {/* facture */}
          <div className="card shadow-sm" style={styles.card}>
            <div className="card-header" style={styles.cardHeader}>
              <h5 className="h5" style={styles.CardHeaderTxt}>{i18n.t("bill.label", { lng })}</h5>
            </div>
            <ul className="list-group list-group-flush">
              {/** */}
              {taxes && taxes.map((item, index) => {
                return <li style={styles.liPaddingSm} className="list-group-item">
                  <span>{item.name + " "}{item.val && item.val}
                  </span>
                  <span style={styles.rFloat}><CurrHolder amount={item.total} curr={curr} />
                  </span>
                </li>
              })}
              <li
                className="list-group-item font-weight-bold"
                style={styles.liPadding}
              >
                <span>Total</span>
                <span style={{ float: "right", fontSize: "1.5rem" }}>
                  <CurrHolder amount={totalPrice} curr={curr} />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row" style={styles.rowPadding}>
        <div className="col-sm">
          {/* PAY BTN */}
          <a
            class="primary-btn"
            onClick={() => {
              //disable button while request is being handeld
              cLoading(true);
              cSubmitForm(true);
            }}
            style={{
              background: themeColor,
              float: "right",
              marginRight: ".5rem",
              pointerEvents: loading ? "none" : "all",
              ...style.bsBtnFix
            }}
          >
            {loading ? (

              <Spinner height={"1.3rem"} width={"1.3rem"} marginRight={".3rem"} />
            ) : i18n.t("pay.label", { lng })}

          </a>

          <Link
            to="/renting"
            style={{
              background: "darkgrey",
              marginRight: ".5rem",
              marginBottom: ".5rem",
            }}
            className="primary-btn "
          >
            {i18n.t("cancel.label", { lng })}
          </Link>
          <a
            style={{ background: "darkgrey", color: "#fff", cursor: "pointer" }}
            className="primary-btn "
            //go back programmaticly to have access to location state
            onClick={() => history.goBack()}
          >
            {i18n.t("changeDate.label", { lng })}
          </a>
        </div>
      </div>

    </div>

  );
}
export default withRouter(inject("store")(observer(BillContent)));
